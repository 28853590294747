import React, { useEffect, useState } from "react";
import { Form, FormLabel } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { parseUrlFromModel } from "../helpers/asset";
import Accordion from "react-bootstrap/Accordion";

import { OwnerSchema, UserSchemaIn } from "./Validation";
import ErrorMassage from "./ErrorMassage.jsx";
import CustomButton from "./customComponents/CustomButton";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
// import formInitialValues from './formInitialValues'
import * as yup from "yup";
import {
  createUserAsyncThunk,
  updateUserAsyncThunk,
} from "../redux/pagesSlices/userSlice";

const ContactCreateAndUpdate = ({ onHide }) => {
  const [image, setImages] = useState(null);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState();
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.RightSidebarBase
  );
  const oldData = modelArgs.args?.member;
  const d = useDispatch();

  const authErr = useSelector(
    (state) => state.owner?.errorMessages?.createOwnerAsyncThunk
  );

  // console.log("authErr", authErr)

  const createOwnerHandler = (data) => {
    if (oldData?.user_id) {
      d(
        updateUserAsyncThunk({
          data: {
            name: data.name,
            user_metadata: {
              date: data.user_metadata.date,
              grade: data?.user_metadata?.grade,
              gender: data?.user_metadata?.gender,
            },
            // username: data.username,
            // roles: data.roles,
            // email: data.email,

            // user_metadata: {
            //   phone: data.user_metadata.phone,
            // },

            password: data.password,
            connection: "Username-Password-Authentication",
            nickname: data.nickname,
            given_name: data.given_name,
            family_name: data.family_name,
          },
          id: oldData?.user_id,
          callBack: () => {
            toast.success("Updated User Successfully");
            onHide();
          },
        })
      );
    } else {
      d(
        createUserAsyncThunk({
          data,
          callBack: () => {
            toast.success("Create User Successfully!");
            onHide();
          },
        })
      );
    }
  };
  const closeModel = () => {
    // if (oldData?.callBack) oldData.callBack(oldData[oldData.type])
    d(handleModel({ model: "RightSidebarBase", state: false }));
  };

  // // role
  // let typesRole = [
  //   { id: 1, name: "User" },
  //   { id: 2, name: "Owner" },
  //   { id: 3, name: "Customer" },
  // ];
  // const options =
  //   typesRole &&
  //   typesRole?.map((e) => {
  //     return { value: e.id, label: e.name };
  //   });

  // space
  let typesSpace = [
    { id: 1, name: "Ilmiya" },
    { id: 2, name: "Aflah" },
  ];
  const typeSpaceOption =
    typesSpace &&
    typesSpace?.map((e) => {
      return { value: e.id, label: e.name };
    });

  // tags
  let typesTags = [
    { id: 1, name: "English" },
    { id: 2, name: "Maths" },
  ];
  const typeTagsOption =
    typesTags &&
    typesTags?.map((e) => {
      return { value: e.id, label: e.name };
    });

  const gradeOptions = [
    { label: 'Excellent', value: '1' },
    { label: 'Good', value: '2' },
    { label: 'Satisfactory', value: '3' },
    { label: 'Below Average', value: '4' },
    { label: 'Fail', value: '5' },
  ];

  const genderOptions = [
    { label: 'Male', value: '1' },
    { label: 'Female', value: '2' },
  ];

  // Define the Yup validation schema
  const OwnerSchemaIn = yup.object().shape({
    name: yup.string().required("First name is required"),
    username: yup
      .string()
      .required("Username is required")
      .matches(/^\S*$/, "No spaces allowed in user name"),
    email: yup.string().email("Invalid email").required("Email is required"),
    password: oldData?.user_id
      ? yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        )
        .min(8, "Password must be at least 8 characters long")
      : yup
        .string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        )
        .min(8, "Password must be at least 8 characters long"),
    connection: yup.string().required("connection is required"),
    nickname: yup.string().required("Last name is required"),
    given_name: yup.string().required("Given name is required"),
    family_name: yup.string().required("Family name is required"),
    user_metadata: yup.object().shape({
      date: yup
        .string()
        // .matches(/^\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format XXX-XXX-XXXX')
        .required("Date of birth is required"),

      grade: yup
        .string()
        .required("Grade is required"),
      gender: yup
        .string()
        .required("Select Gender"),
    }),
    // roles: yup.array().required("roles is Required"),
  });


  const initialValues = {
    ...(oldData?.user_id
      ? {
        ...oldData,
        name: oldData.name,
        username: oldData?.username,
        email: oldData.email,
        password: oldData?.password,
        connection: "Username-Password-Authentication",
        // connection: oldData?.identities[0]?.connection,
        // roles: oldData?.user_metadata?.roles,
        roles: ["User"],
        nickname: oldData.nickname,
        given_name: oldData.given_name,
        family_name: oldData.family_name,
        user_id: undefined,
      }
      : {
        name: "",
        username: "",
        email: "",

        password: "",
        connection: "Username-Password-Authentication",
        roles: ["User"],
        nickname: "",
        given_name: "",
        family_name: "",
        user_metadata: {
          date: "",
          grade: "",
          gender: "male",
        },
        // picture: ""
      }),
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={createOwnerHandler}
        validationSchema={OwnerSchemaIn}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div className="create-course-sibebar">
            <h4 className="heading">
              {oldData?.user_id ? "Update User" : "Create User"}
            </h4>

            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    onChange={handleChange("username")}
                    onBlur={handleBlur("username")}
                    value={values?.username}
                    disabled={oldData?.user_id ? true : false}
                    className={oldData?.user_id ? "disable-field" : null}
                  />
                  <ErrorMassage
                    error={errors.username}
                    visible={touched.username}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Email"
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values?.email}
                  />
                  <ErrorMassage
                    error={errors?.email}
                    visible={touched?.email}
                  />
                </Form.Group>
              </div>

              {/* <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Phone No</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Phone Number"
                    name="user_metadata.phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.user_metadata?.phone || ''}
                  />
                  <ErrorMassage error={errors?.user_metadata?.phone} visible={touched?.user_metadata?.phone} />
                </Form.Group>
              </div> */}

              <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Password"
                    onChange={handleChange("password")}
                    onBlur={handleBlur("password")}
                    value={values?.password}
                  />
                  <ErrorMassage
                    error={errors?.password}
                    visible={touched?.password}
                  />
                </Form.Group>
              </div>

              {/* <div className="col-lg-12">

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Connection</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    placeholder="Enter Connection"
                    onChange={handleChange("connection")}
                    onBlur={handleBlur("connection")}
                    value={values?.connection}
                  />
                  <ErrorMassage error={errors?.connection} visible={touched?.connection} />
                </Form.Group>
              </div> */}

              {/* <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Roles</Form.Label>
                  <Select
                    options={options}
                    name="roles"
                    onInputChange={(e) => {
                      setSearch(e);
                    }}
                    onChange={(selectedOptions) => {
                      const selectedRoleNames = selectedOptions.map(
                        (option) => option.label
                      );
                      console.log(
                        "🚀 ~ file: OwnerCreateAndUpdate.jsx:254 ~ OwnerCreateAndUpdate ~ selectedRoleNames:",
                        selectedRoleNames
                      );
                      setFieldValue("roles", selectedRoleNames);
                    }}
                    value={options.find(
                      (option) => option.label === values.roles
                    )}
                    onBlur={handleBlur("roles")}
                    isSearchable={false}
                    isMulti
                  />
                  <ErrorMassage error={errors.roles} visible={touched.roles} />
                </Form.Group>
                {values.roles && (
                  <ul>
                    {values.roles.map((role, index) => (
                      <li key={index}>{role}</li>
                    ))}
                  </ul>
                )}
              </div> */}

              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    value={values?.name}
                  />
                  <ErrorMassage error={errors.name} visible={touched.name} />
                </Form.Group>
              </div>

              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    onChange={handleChange("nickname")}
                    onBlur={handleBlur("nickname")}
                    value={values?.nickname}
                  />
                  <ErrorMassage
                    error={errors.nickname}
                    visible={touched.nickname}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Given Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Given Name"
                    onChange={handleChange("given_name")}
                    onBlur={handleBlur("given_name")}
                    value={values?.given_name}
                  />
                  <ErrorMassage
                    error={errors.given_name}
                    visible={touched.given_name}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Family Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Family Name"
                    onChange={handleChange("family_name")}
                    onBlur={handleBlur("family_name")}
                    value={values?.family_name}
                  />
                  <ErrorMassage
                    error={errors.family_name}
                    visible={touched.family_name}
                  />
                </Form.Group>
              </div>

              {/* <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Grade</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={values?.user_metadata?.grade || ''}
                    onChange={(event) =>
                      setFieldValue('user_metadata.grade', event.target.value)
                    }
                    onBlur={handleBlur}
                  >
                    <option value="">Select Grade</option>
                    {gradeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <ErrorMassage
                    error={errors?.user_metadata?.grade}
                    visible={touched?.user_metadata?.grade}
                  />
                </Form.Group>
              </div> */}

              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Grade</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Grade"
                    name="user_metadata.grade"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.user_metadata?.grade || ""}
                  />
                  <ErrorMassage
                    error={errors?.user_metadata?.grade}
                    visible={touched?.user_metadata?.grade}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Gender</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={values?.user_metadata?.gender || ''}
                    onChange={(event) =>
                      setFieldValue('user_metadata.gender', event.target.value)
                    }
                    onBlur={handleBlur}
                  >
                    {/* <option value="">Select Gender</option> */}
                    {/* {genderOptions.map((option) => ( */}
                    <option key={"male"} value={"male"}>
                      Male
                    </option>
                    <option key={"female"} value={"female"}>
                      Female
                    </option>
                    {/* ))} */}
                  </Form.Select>

                  <ErrorMassage
                    error={errors?.user_metadata?.gender}
                    visible={touched?.user_metadata?.gender}
                  />
                </Form.Group>
              </div>



              {/* <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    

                    value={values?.user_metadata?.date || ''}
                    onChange={(value) => setFieldValue('user_metadata.date', value)}
                    onBlur={handleBlur}
                  />
                  <ErrorMassage error={errors?.user_metadata?.date} visible={touched?.user_metadata?.date} />
                </Form.Group>
              </div> */}

              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    value={values?.user_metadata?.date || ''}
                    onChange={(event) =>
                      setFieldValue('user_metadata.date', event.target.value)
                    }
                    onBlur={handleBlur}
                    max="2000-12-31" // Set the maximum date allowed to December 31, 2010
                  />
                  <ErrorMassage
                    error={errors?.user_metadata?.date}
                    visible={touched?.user_metadata?.date}
                  />
                </Form.Group>
              </div>

            </div>

            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Attach Thumbnail</Form.Label>
              <div
                className="img-uploader"
                onClick={() => openContent(setFieldValue)}
              >
               
                  <div className="icon-box">
                    <i className="fa-regular fa-image"></i>
                  </div>
              </div>
              <ErrorMassage error={errors.image} visible={touched.image} />
            </Form.Group> */}

            {/* <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Actions</Accordion.Header>
                <Accordion.Body>                  

                  <div className="col-lg-12">

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Select Space</Form.Label>
                      <Select
                        options={typeSpaceOption}
                        placeholder={"Select Space<"}
                        name="space"
                        onInputChange={(e) => {
                          setSearch(e);
                        }}
                        onChange={(selectedOptions) => {
                          const selectedRoleNames = selectedOptions.map((option) => option.label);
                          console.log("🚀 ~ file: OwnerCreateAndUpdate.jsx:254 ~ OwnerCreateAndUpdate ~ selectedRoleNames:", selectedRoleNames)
                          setFieldValue("space", selectedRoleNames);
                        }}
                        value={options.find((option) => option.label === values.space)}
                        onBlur={handleBlur("space")}
                        isSearchable={false}
                        isMulti
                      />
                      <ErrorMassage
                        error={errors.space}
                        visible={touched.space}
                      />
                    </Form.Group>
                    {values.space && (
                      <ul>
                        {values.space.map((space, index) => (
                          <li key={index}>{space}</li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="col-lg-12">

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Add Tags</Form.Label>
                      <Select
                        options={typeTagsOption}
                        placeholder={"Select Tags"}
                        name="tag"
                        onInputChange={(e) => {
                          setSearch(e);
                        }}
                        onChange={(selectedOptions) => {
                          const selectedRoleNames = selectedOptions.map((option) => option.label);
                          console.log("🚀 ~ file: OwnerCreateAndUpdate.jsx:254 ~ OwnerCreateAndUpdate ~ selectedRoleNames:", selectedRoleNames)
                          setFieldValue("tag", selectedRoleNames);
                        }}
                        value={options.find((option) => option.label === values.tag)}
                        onBlur={handleBlur("tag")}
                        isSearchable={false}
                        isMulti
                      />
                      <ErrorMassage
                        error={errors.tag}
                        visible={touched.tag}
                      />
                    </Form.Group>
                    {values.tag && (
                      <ul>
                        {values.tag.map((tag, index) => (
                          <li key={index}>{tag}</li>
                        ))}
                      </ul>
                    )}
                  </div>

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Action</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}

            <div className="bottom-btns">
              <button className="btn btn-light" onClick={() => closeModel()}>
                Cancel
              </button>
              {/* <button
                onClick={() => handleSubmit()}
                className="btn btn-primary"
                type="submit"
              >
                Save
              </button> */}

              <CustomButton
                reducer={"user"}
                action={
                  oldData?.user_id
                    ? "updateUserAsyncThunk"
                    : "createUserAsyncThunk"
                }
                title={oldData?.user_id ? "Update" : "Save"}
                onClick={handleSubmit}
                loadingText={oldData?.user_id ? "Updating..." : "Creating..."}
                className="btn btn-primary"
              />
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default ContactCreateAndUpdate;
