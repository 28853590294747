import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'
import { ApiRequests } from '../../service/ApiRequests'
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder
} from '../../helpers/detectError'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

// Start User Slices
/// ////////////////////////////////////////////////

export const getUsersAsyncThunk = createAsyncThunk(
  'user/getUsersAsyncThunk',
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOwners(params)
    return response?.data
  })
)

export const getUsersByIdsAsyncThunk = createAsyncThunk(
  'user/getUsersByIdsAsyncThunk',
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOwner(params)
    return response?.data
  })
)

export const getUserAsyncThunk = createAsyncThunk(
  'user/getUserAsyncThunk',
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getOwner(id)
    return response?.data
  })
)
export const getUserForListAsyncThunk = createAsyncThunk(
  'user/getUserForListAsyncThunk',
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOwners(params)
    return response?.data
  })
)

export const createUserAsyncThunk = createAsyncThunk(
  'user/createUserAsyncThunk',
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState()
    const response = await ApiRequests.createOwner(data)
    if (response.status == 204) {
      toast.success('User Create Successfully!')
    }
    if (callBack) callBack()
    const params = {}
    const state1 = getState().listings
    if (state1.search) params.name = state1.search
    if (state1.order) params.sortBy = `name:${state1.order}`
    dispatch(
      getUsersAsyncThunk({ ...params, populate: 'user_id', role: 'User' })
    )
    // dispatch(getUsersByIdsAsyncThunk({ ...state.users?.paramsForThunk?.getUsersByIdsAsyncThunk}))
    return response?.data
  })
)

export const updateUserAsyncThunk = createAsyncThunk(
  'user/updateUserAsyncThunk',
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState()
    // console.log(state.users?.paramsForThunk)
    const response = await ApiRequests.updateOwner({ id, data })
    if (response.status == 204) {
      toast.success('User Updated Successfully!')
    }
    if (callBack) callBack()
    const params = {}
    const state1 = getState().listings
    if (state1.search) params.name = state1.search
    if (state1.order) params.sortBy = `name:${state1.order}`
    dispatch(
      getUsersAsyncThunk({ ...params, populate: 'user_id', role: 'User' })
    )
    // dispatch(getUsersByIdsAsyncThunk({ populate: "image,user_id", ...state.users?.paramsForThunk?.getUsersByIdsAsyncThunk, page: 1 }))
    return response?.data
  })
)

export const deleteUserAsyncThunk = createAsyncThunk(
  'user/deleteUserAsyncThunk',
  catchAsync(async (id, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteOwner(id)
    if (response.status == 204) {
      toast.success('User Deleted Successfully!')
      const params = {}
      const state = getState().listings
      if (state.search) params.name = state.search
      if (state.order) params.sortBy = `name:${state.order}`
      dispatch(
        getUsersAsyncThunk({ ...params, populate: 'user_id', role: 'User' })
      )
    } else {
      toast.error(response.error)
    }
    return id
  })
)

export const getTopFiveLeaderboardAsyncThunk = createAsyncThunk(
  'user/getTopFiveLeaderboardAsyncThunk',
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getTopFiveRank(params?.token)
    return response?.data
  })
)

export const getHighlightedCountsAsyncThunk = createAsyncThunk(
  'user/getHighlightedCountsAsyncThunk',
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getHighlightedCounts(params?.token)
    return response?.data
  })
)

export const getTopClassTrophiesAsyncThunk = createAsyncThunk(
  'user/getTopClassTrophiesAsyncThunk',
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getTopClassTrophies(params?.token)
    return response?.data
  })
)

/// ////////////////////////////////////////////////

const initialState = {
  // news states
  users: {
    page: 1,
    users: [],
    totalPages: 1
  },
  usersCount: {
    page: 1,
    results: [],
    totalPages: 1
  },
  inviteUser: {
    page: 1,
    results: [],
    totalPages: 1
  },
  userExport: {
    page: 1,
    results: [],
    totalPages: 1
  },
  userRole: {
    page: 1,
    results: [],
    totalPages: 1
  },
  usersList: {
    page: 1,
    results: [],
    totalPages: 1
  },
  topFiveUser: {
    page: 1,
    results: [],
    totalPages: 1
  },
  highlightedCounts: {
    page: 1,
    results: [],
    totalPages: 1
  },
  topClassTrophies: {
    page: 1,
    results: [],
    totalPages: 1
  },
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: 'asce'
}

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSearchValue (state, action) {
      state.search = action.payload
    },
    setCategoryValue (state, action) {
      state.categoryId = action.payload
    },
    setOrderValue (state, action) {
      state.order = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getUsersAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.users = {
            page: 1,
            results: [],
            totalPages: 1
          }
        }
      })
      .addCase(getUsersAsyncThunk.fulfilled, (state, action) => {
        console.log(
          '🚀 ~ file: userSlice.js:209 ~ .addCase ~ action:',
          action?.payload
        )
        if (action.payload?.page > 1) {
          state.users = {
            ...action.payload,
            users: state?.users?.results.concat(action?.payload?.results)
          }
        } else {
          state.users = action.payload
          // console.log("🚀 ~ file: userSlice.js:170 ~ .addCase ~ on.payload:", action.payload)
        }
      })
      .addCase(getUsersByIdsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.usersCount = {
            ...action.payload,
            results: state?.usersCount?.results.concat(
              action?.payload?.results
            )
          }
        } else {
          state.usersCount = action.payload
        }
      })

      .addCase(getUserForListAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.usersList = {
            ...action.payload,
            results: state?.usersList?.results.concat(action?.payload?.results)
          }
        } else {
          state.usersList = action.payload
        }
      })
      .addCase(getUserAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.story = {
            ...action.payload,
            results: state?.story?.results.concat(action?.payload?.results)
          }
        } else {
          state.story = action.payload
        }
      })
      .addCase(deleteUserAsyncThunk.fulfilled, (state, action) => {
        state.users = {
          ...state.users,
          totalResults: state.users?.totalResults - 1,
          results: state.users?.results.filter((e) => e.id != action.payload)
        }
        state.usersCount = {
          ...state.usersCount,
          totalResults: state.usersCount?.totalResults - 1,
          results: state.usersCount?.results.filter(
            (e) => e.id != action.payload
          )
        }
      })

      .addCase(getTopFiveLeaderboardAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.topFiveUser = {
            page: 0,
            results: [],
            totalPages: 1
          }
        }
      })
      .addCase(getTopFiveLeaderboardAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.topFiveUser = {
            ...action.payload,
            topFiveUser: state?.topFiveUser?.results.concat(
              action?.payload?.results
            )
          }
        } else {
          state.topFiveUser = action.payload
        }
      })

      .addCase(getHighlightedCountsAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.highlightedCounts = {
            page: 0,
            results: [],
            totalPages: 1
          }
        }
      })
      .addCase(getHighlightedCountsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.highlightedCounts = {
            ...action.payload,
            highlightedCounts: state?.highlightedCounts?.results.concat(
              action?.payload?.results
            )
          }
        } else {
          state.highlightedCounts = action.payload
        }
      })

      .addCase(getTopClassTrophiesAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.topClassTrophies = {
            page: 0,
            results: [],
            totalPages: 1
          }
        }
      })
      .addCase(getTopClassTrophiesAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.topClassTrophies = {
            ...action.payload,
            topClassTrophies: state?.topClassTrophies?.results.concat(
              action?.payload?.results
            )
          }
        } else {
          state.topClassTrophies = action.payload
        }
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getUsersAsyncThunk,
            getUserAsyncThunk,
            getUsersByIdsAsyncThunk,
            deleteUserAsyncThunk,
            createUserAsyncThunk,
            updateUserAsyncThunk,
            getUserForListAsyncThunk,
            getTopFiveLeaderboardAsyncThunk,
            getHighlightedCountsAsyncThunk,
            getTopClassTrophiesAsyncThunk
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      )
  }
})

export default userSlice.reducer
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  userSlice.actions
