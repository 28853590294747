import React from 'react'
import { useSelector } from 'react-redux'
import Confirmation from './Confirmation'
import ExportUserModel from './ExportUserModel'
import RightSidebarBase from './RightSidebar/rightSidebarBase'
import LeftSidebarBase from '../../console/modals/leftSidebarBase'
import OpenImportFailUserListModel from './openImportFailUserListModel.js'
import CreatSpace from '../modals/CreatSpace.jsx'
import ContentModel from '../contentModel/index.jsx'
import AddDictionary from './AddDictionary.jsx'

export default function Index () {
  const user = useSelector((s) => s.auth.user)

  return (
    <>
      <Confirmation />
      <ExportUserModel />
      {/* {user ?  */}
      <RightSidebarBase />
      <LeftSidebarBase />
      <OpenImportFailUserListModel />
      <CreatSpace />
      <ContentModel />
      <AddDictionary />
      {/* : ""} */}
    </>
  )
}
