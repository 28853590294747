import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import AudioCard from './cards/AudioCard'
import { getContentsAsyncThunk } from '../../redux/pagesSlices/contentSlice'
import ProviderErrorLoadingScroll from '../ProviderErrorLoadingScroll'

function AudiosList ({ _key }) {
  const d = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getContent(searchTerm)
      // Send Axios request here
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])
  useEffect(() => {
    if (_key === 'audio') getContent()
  }, [_key])
  const getContent = () => {
    d(
      getContentsAsyncThunk({
        mimeType: 'audio',
        limit: 30,
        sortBy: 'updatedAt:desc',
        ...(searchTerm && { name: searchTerm })
      })
    )
  }

  return (
    <div>
      <Form.Control
        type='text'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder='Search...'
        className='mb-3'
      />
      <div className='row' id='getContentsAsyncThunk_audio_parent'>
        <ProviderErrorLoadingScroll
          emtpyMessage='Audios will show here!'
          reducer='contents'
          action='getContentsAsyncThunk'
          dataKey='audios'
          loadingIndicator='Loadingdata'
          Component={AudioCard}
          InfiniteScroll_props={{
            scrollableTarget: 'getContentsAsyncThunk_audio_parent'
          }}
          loadMore={(e) =>
            d(
              getContentsAsyncThunk({
                ...e,
                mimeType: 'audio',
                sortBy: 'updatedAt:desc',
                ...(searchTerm && { name: searchTerm })
              })
            )}
          // Parent={Patent}
          asyncThunk={getContentsAsyncThunk}
        />
      </div>
    </div>
  )
}
export default React.memo(AudiosList)
