import React from 'react'
import Logo from '.././images/AllProducts/Group.svg'
import people from '.././images/AllProducts/account_box_FILL0_wght400_GRAD0_opsz24 (1) 1.svg'
import students from '.././images/AllProducts/Student.svg'
import file from '.././images/AllProducts/description_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 2.svg'
import guardian from '.././images/AllProducts/Guardians.svg'
import family from '.././images/AllProducts/Families.svg'
import analytic from '.././images/AllProducts/analytics.svg'
import insight from '.././images/AllProducts/Insights.svg'
import grade from '.././images/AllProducts/Grades.svg'
import stream from '.././images/AllProducts/Streams.svg'
import engage from '.././images/AllProducts/wysiwyg.svg'
import window from '.././images/AllProducts/select_window_2.svg'
import assign from '.././images/AllProducts/assignment.svg'
import topic from '.././images/AllProducts/topic.svg'
import space from '.././images/AllProducts/casino.svg'
import store from '.././images/AllProducts/widgets.svg'
import { redirect_uris } from '../constants'

const AllProducts = () => {
  const customToken = localStorage.getItem('custom-token')
  return (
    <>
      <div className='container'>
        <div className='page-title'>
          <img src={Logo} alt='Logo' />
          <p>All Products</p>
        </div>

        <div className='people-section'>
          <img src={people} alt='People-Icon' />
          <p>People</p>
        </div>

        <div className='table-wrapper'>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th style={{ textAlign: 'end' }}>Docs</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <a
                    href={redirect_uris.People + `?token=${customToken}`}
                    target='blank'
                  >
                    <div className='image-wrapper'>
                      <img src={students} alt='Students' />
                      <p>Students</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Students history</p>
                </td>

                <td style={{ textAlign: 'end' }}>
                  <div className='file-img'>
                    <img src={file} alt='File' />
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.People +
                      'guardians' +
                      `?token=${customToken}`
                    }
                    target='blank'
                  >
                    <div className='image-wrapper'>
                      <img src={guardian} alt='Students' />
                      <p>Guardians</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Guardians history</p>
                </td>

                <td style={{ textAlign: 'end' }}>
                  <div className='file-img'>
                    <img src={file} alt='File' />
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.People + 'family' + `?token=${customToken}`
                    }
                    target='blank'
                  >
                    <div className='image-wrapper'>
                      <img src={family} alt='Students' />
                      <p>Family</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Family history</p>
                </td>

                <td style={{ textAlign: 'end' }}>
                  <div className='file-img'>
                    <img src={file} alt='File' />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* section-2 */}

        <div className='people-section'>
          <img src={analytic} alt='People-Icon' />
          <p>Analytics</p>
        </div>

        <div className='table-wrapper'>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th style={{ textAlign: 'end' }}>Docs</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <a
                    href={redirect_uris.Analytics + `?token=${customToken}`}
                    target='blank'
                  >
                    <div className='image-wrapper'>
                      <img src={insight} alt='Students' />
                      <p>Insights</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Insights history</p>
                </td>

                <td style={{ textAlign: 'end' }}>
                  <div className='file-img'>
                    <img src={file} alt='File' />
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.Analytics +
                      'grade' +
                      `?token=${customToken}`
                    }
                    target='blank'
                  >
                    <div className='image-wrapper'>
                      <img src={grade} alt='Students' />
                      <p>Grades</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Grades history</p>
                </td>

                <td style={{ textAlign: 'end' }}>
                  <div className='file-img'>
                    <img src={file} alt='File' />
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.Analytics +
                      'stream' +
                      `?token=${customToken}`
                    }
                    target='blank'
                  >
                    <div className='image-wrapper'>
                      <img src={stream} alt='Streams' />
                      <p>Streams</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Streams history</p>
                </td>

                <td style={{ textAlign: 'end' }}>
                  <div className='file-img'>
                    <img src={file} alt='File' />
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.Analytics +
                      'leaderboard' +
                      `?token=${customToken}`
                    }
                    target='blank'
                  >
                    <div className='image-wrapper'>
                      <img src={stream} alt='Streams' />
                      <p>LeaderBoards</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>LeaderBoards history</p>
                </td>

                <td style={{ textAlign: 'end' }}>
                  <div className='file-img'>
                    <img src={file} alt='File' />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* section-3 */}

        <div className='people-section'>
          <img src={engage} alt='engage' />
          <p>Engage</p>
        </div>

        <div className='table-wrapper'>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th style={{ textAlign: 'end' }}>Docs</th>
              </tr>
            </thead>

            <tbody>
              {/* <tr>
                                <td>
                                    <a
                                        href={
                                            redirect_uris.Studio +
                                            "discover" +
                                            `?token=${customToken}`
                                        }
                                        target="blank"
                                    >
                                        <div className='image-wrapper'>
                                            <img src={window} alt='Students' />
                                            <p>Discover</p>
                                        </div>
                                    </a>
                                </td>

                                <td>
                                    <p mb-0  >All students history</p>
                                </td>

                                <td style={{ textAlign: 'end' }}>
                                    <div className='file-img'>
                                        <img src={file} alt='File' />
                                    </div>
                                </td>
                            </tr> */}

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.Studio +
                      'assignment' +
                      `?token=${customToken}`
                    }
                    target='blank'
                  >
                    <div className='image-wrapper'>
                      <img src={assign} alt='Students' />
                      <p>Assign</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Assignment history</p>
                </td>

                <td style={{ textAlign: 'end' }}>
                  <div className='file-img'>
                    <img src={file} alt='File' />
                  </div>
                </td>
              </tr>

              {/* <tr>
                                <td>
                                    <div className='image-wrapper'>
                                        <img src={topic} alt='Students' />
                                        <p>Pathways</p>
                                    </div>
                                </td>

                                <td>
                                    <p mb-0  >All students history</p>
                                </td>

                                <td style={{ textAlign: 'end' }}>
                                    <div className='file-img'>
                                        <img src={file} alt='File' />
                                    </div>
                                </td>
                            </tr> */}

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.Admin + 'space' + `?token=${customToken}`
                    }
                    target='blank'
                  >
                    <div className='image-wrapper'>
                      <img src={space} alt='Students' />
                      <p>Spaces</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Spaces history</p>
                </td>

                <td style={{ textAlign: 'end' }}>
                  <div className='file-img'>
                    <img src={file} alt='File' />
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.Studio + 'apps' + `?token=${customToken}`
                    }
                    target='blank'
                  >
                    <div className='image-wrapper'>
                      <img src={store} alt='Students' />
                      <p>Appstore</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Appstore history</p>
                </td>

                <td style={{ textAlign: 'end' }}>
                  <div className='file-img'>
                    <img src={file} alt='File' />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default AllProducts
