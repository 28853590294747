import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Start Group Slices
///////////////////////////////////////////////////

export const getGroupsAsyncThunk = createAsyncThunk(
  "group/getGroupsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getGroups(params);
    return response?.data;
  })
);

export const getGroupAsyncThunk = createAsyncThunk(
  "group/getGroupAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getGroup(id);
    return response?.data;
  })
);

export const createGroupAsyncThunk = createAsyncThunk(
  "group/createGroupAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.createGroup(data);
    if (response.status == 204) {
      toast.success("Group Create Successfully!");
    }
    if (callBack) callBack();
    // dispatch(getGroupsByIdsAsyncThunk({ ...state.groups?.paramsForThunk?.getGroupsByIdsAsyncThunk}))
    return response?.data;
  })
);

export const updateGroupAsyncThunk = createAsyncThunk(
  "group/updateGroupAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    // console.log(state.groups?.paramsForThunk)
    const response = await ApiRequests.updateGroup({ id, data });
    if (response.status == 204) {
      toast.success("Group Updated Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(
      getGroupsAsyncThunk({ ...params, populate: "user_id", role: "Group" })
    );
    // dispatch(getGroupsByIdsAsyncThunk({ populate: "image,user_id", ...state.groups?.paramsForThunk?.getGroupsByIdsAsyncThunk, page: 1 }))
    return response?.data;
  })
);

export const deleteGroupAsyncThunk = createAsyncThunk(
  "group/deleteGroupAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteGroup(id);
    if (response.status == 204) {
      toast.success("Group Deleted Successfully!");
      let params = {};
      let state = getState().listings;
      if (state.search) params.name = state.search;
      if (state.order) params.sortBy = `name:${state.order}`;
      dispatch(
        getGroupsAsyncThunk({ ...params, populate: "user_id", role: "Group" })
      );
    } else {
      toast.error(response.error);
    }
    return id;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  groups: {
    page: 1,
    users: [],
    totalPages: 1,
  },
  groupsCount: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  inviteGroup: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  userExport: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  userRole: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  groupsList: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const groupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getGroupsAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.groups = {
            page: 1,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(getGroupsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.groups = {
            ...action.payload,
            users: state?.groups?.results.concat(action?.payload?.results),
          };
        } else {
          state.groups = action.payload;
          // console.log("🚀 ~ file: groupSlice.js:170 ~ .addCase ~ on.payload:", action.payload)
        }
      })

      .addCase(getGroupAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.story = {
            ...action.payload,
            results: state?.story?.results.concat(action?.payload?.results),
          };
        } else {
          state.story = action.payload;
        }
      })
      .addCase(deleteGroupAsyncThunk.fulfilled, (state, action) => {
        state.groups = {
          ...state.groups,
          totalResults: state.groups?.totalResults - 1,
          results: state.groups?.results.filter((e) => e.id != action.payload),
        };
        state.groupsCount = {
          ...state.groupsCount,
          totalResults: state.groupsCount?.totalResults - 1,
          results: state.groupsCount?.results.filter(
            (e) => e.id != action.payload
          ),
        };
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getGroupsAsyncThunk,
            getGroupAsyncThunk,
            deleteGroupAsyncThunk,
            createGroupAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default groupSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  groupSlice.actions;
