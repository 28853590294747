import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Start Owner Slices
///////////////////////////////////////////////////

export const getOwnersAsyncThunk = createAsyncThunk(
  "owner/getOwnersAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOwners(params);
    return response?.data;
  })
);

export const getOwnersByIdsAsyncThunk = createAsyncThunk(
  "owner/getOwnersByIdsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOwner(params);
    return response?.data;
  })
);

export const getOwnerAsyncThunk = createAsyncThunk(
  "owner/getOwnerAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getOwner(id);
    return response?.data;
  })
);
export const getOwnerForListAsyncThunk = createAsyncThunk(
  "owner/getOwnerForListAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOwners(params);
    return response?.data;
  })
);

export const createOwnerAsyncThunk = createAsyncThunk(
  "owner/createOwnerAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.createOwner(data);
    if (response.status == 204) {
      toast.success("Owner Create Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `username:${state1.order}`;
    dispatch(
      getOwnersAsyncThunk({ ...params, populate: "user_id", role: "Admin" })
    );
    return response?.data;
  })
);

export const createOwnerInviteAsyncThunk = createAsyncThunk(
  "owner/createOwnerInviteAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.createOwnerInvite(data);
    if (response.status == 204) {
      toast.success("Invite Owner Updated Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const createUserExportAsyncThunk = createAsyncThunk(
  "owner/createUserExportAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.createUserExport(data);
    if (response.status == 204) {
      toast.success("User Export Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const createUserImportAsyncThunk = createAsyncThunk(
  "owner/createUserImportAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.createUserImport(data);
    if (response.status == 204) {
      toast.success("User Import Successfully!");
    }
    if (callBack) callBack(response?.data);
    return response?.data;
  })
);

export const createUserRoleAsyncThunk = createAsyncThunk(
  "owner/createUserRoleAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.createUserRole(data);
    if (response.status == 204) {
      toast.success("User Role Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const updateOwnerAsyncThunk = createAsyncThunk(
  "owner/updateOwnerAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    // console.log(state.owners?.paramsForThunk)
    const response = await ApiRequests.updateOwner({ id, data });
    if (response.status == 204) {
      toast.success("Owner Updated Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `username:${state1.order}`;
    dispatch(
      getOwnersAsyncThunk({ ...params, populate: "user_id", role: "Admin" })
    );
    // dispatch(getOwnersByIdsAsyncThunk({ populate: "image,user_id", ...state.owners?.paramsForThunk?.getOwnersByIdsAsyncThunk, page: 1 }))
    return response?.data;
  })
);
export const createInviteAdministrationAsyncThunk = createAsyncThunk(
  "owner/createInviteAdministrationAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState();
    // console.log(state.owners?.paramsForThunk)
    const response = await ApiRequests.createInvitation(data);
    if (response.status == 204) {
      toast.success("Owner Updated Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);
export const getInviteAdministrationsAsyncThunk = createAsyncThunk(
  "owner/getInviteAdministrationsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getInvitations(params);
    console.log("response?.data", response?.data);
    return response?.data;
  })
);
export const updateInviteAdministrationAsyncThunk = createAsyncThunk(
  "owner/updateInviteAdministrationAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    // console.log(state.owners?.paramsForThunk)
    const response = await ApiRequests.updateInvitation({ id, data });
    if (response.status == 204) {
      toast.success("Owner Updated Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `email:${state1.order}`;
    dispatch(
      getInviteAdministrationsAsyncThunk({
        ...params,
        populate: "user_id",
        role: "Admin",
      })
    );
    return response?.data;
  })
);
export const deleteInviteAdministrationAsyncThunk = createAsyncThunk(
  "owner/deleteInviteAdministrationAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    const response = await ApiRequests.deleteInvitation(id);
    if (response.status == 204) {
      toast.success("invite Deleted Successfully!");
      let params = {};
      let state = getState().listings;
      if (state.search) params.name = state.search;
      if (state.order) params.sortBy = `email:${state.order}`;
      dispatch(
        getInviteAdministrationsAsyncThunk({
          ...params,
          // populate: "user_id",
          // role: "Admin",
        })
      );
    } else {
      toast.error(response.error);
    }
    return id;
  })
);
export const getInviteAdministrationAsyncThunk = createAsyncThunk(
  "owner/getInviteAdministrationAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getInvitation(id);
    return response?.data;
  })
);

export const deleteOwnerAsyncThunk = createAsyncThunk(
  "owner/deleteOwnerAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteOwner(id);
    if (response.status == 204) {
      toast.success("Owner Deleted Successfully!");
      let params = {};
      let state = getState().listings;
      if (state.search) params.name = state.search;
      if (state.order) params.sortBy = `username:${state.order}`;
      dispatch(
        getOwnersAsyncThunk({ ...params, populate: "user_id", role: "Admin" })
      );
    } else {
      toast.error(response.error);
    }
    return id;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  owners: {
    page: 1,
    users: [],
    totalPages: 1,
  },
  ownersCount: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  inviteOwner: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  inviteOwners: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  userExport: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  userImport: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  userRole: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  ownersList: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const ownerSlice = createSlice({
  name: "owners",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getOwnersAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.owners = {
            page: 1,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(getOwnersAsyncThunk.fulfilled, (state, action) => {
        console.log(
          "🚀 ~ file: ownerSlice.js:209 ~ .addCase ~ action:",
          action?.payload
        );
        if (action.payload?.page > 1) {
          state.owners = {
            ...action.payload,
            users: state?.owners?.results.concat(action?.payload?.results),
          };
        } else {
          state.owners = action.payload;
          // console.log("🚀 ~ file: ownerSlice.js:170 ~ .addCase ~ on.payload:", action.payload)
        }
      })
      .addCase(getOwnersByIdsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.ownersCount = {
            ...action.payload,
            results: state?.ownersCount?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.ownersCount = action.payload;
        }
      })

      .addCase(getOwnerForListAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.ownersList = {
            ...action.payload,
            results: state?.ownersList?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.ownersList = action.payload;
        }
      })
      .addCase(getOwnerAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.story = {
            ...action.payload,
            results: state?.story?.results.concat(action?.payload?.results),
          };
        } else {
          state.story = action.payload;
        }
      })
      .addCase(deleteOwnerAsyncThunk.fulfilled, (state, action) => {
        state.owners = {
          ...state.owners,
          totalResults: state.owners?.totalResults - 1,
          results: state.owners?.results.filter((e) => e.id != action.payload),
        };
        state.ownersCount = {
          ...state.ownersCount,
          totalResults: state.ownersCount?.totalResults - 1,
          results: state.ownersCount?.results.filter(
            (e) => e.id != action.payload
          ),
        };
      })
      .addCase(createOwnerInviteAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.inviteOwner = {
            ...action.payload,
            results: state?.inviteOwner?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.inviteOwner = action.payload;
        }
      })

      .addCase(createUserExportAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.userExport = {
            ...action.payload,
            results: state?.userExport?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.userExport = action.payload;
        }
      })

      .addCase(createUserImportAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.userImport = {
            ...action.payload,
            results: state?.userImport?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.userImport = action.payload;
        }
      })

      .addCase(createUserRoleAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.userRole = {
            ...action.payload,
            results: state?.userRole?.results.concat(action?.payload?.results),
          };
        } else {
          state.userRole = action.payload;
        }
      })
      // ----------------------------------------------------------------------

      .addCase(getInviteAdministrationsAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.inviteOwners = {
            page: 1,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(
        getInviteAdministrationsAsyncThunk.fulfilled,
        (state, action) => {
          console.log(
            "🚀 ~ file: ownerSlice.js:209 ~ .addCase ~ action:",
            action?.payload
          );
          if (action.payload?.page > 1) {
            state.inviteOwners = {
              ...action.payload,
              users: state?.inviteOwners?.results.concat(
                action?.payload?.results
              ),
            };
          } else {
            state.inviteOwners = action.payload;
          }
        }
      )
      .addCase(
        deleteInviteAdministrationAsyncThunk.fulfilled,
        (state, action) => {
          state.inviteOwners = {
            ...state.inviteOwners,
            totalResults: state.inviteOwners?.totalResults - 1,
            results: state.inviteOwners?.results.filter(
              (e) => e.id != action.payload
            ),
          };
        }
      )
      .addCase(getInviteAdministrationAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.story = {
            ...action.payload,
            results: state?.story?.results.concat(action?.payload?.results),
          };
        } else {
          state.story = action.payload;
        }
      })
      // ----------------------------------------------------------------------
      // .addCase(createOwnerAsyncThunk.fulfilled, (state, action) => {
      //   state.owners = {
      //     ...state.owners,
      //     totalResults: state.owners?.totalResults+1,
      //     results: [...state.owners?.results, action.payload]
      //   }
      //   state.ownersCount = {
      //     ...state.ownersCount,
      //     totalResults: state.ownersCount?.totalResults+1,
      //     results: [...state.ownersCount?.results, action.payload]
      //   }
      // })
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getOwnersAsyncThunk,
            getOwnerAsyncThunk,
            getOwnersByIdsAsyncThunk,
            deleteOwnerAsyncThunk,
            createOwnerAsyncThunk,
            updateOwnerAsyncThunk,
            createInviteAdministrationAsyncThunk,
            updateInviteAdministrationAsyncThunk,
            getInviteAdministrationAsyncThunk,
            getInviteAdministrationsAsyncThunk,
            deleteInviteAdministrationAsyncThunk,
            createOwnerInviteAsyncThunk,
            createUserExportAsyncThunk,
            createUserImportAsyncThunk,
            createUserRoleAsyncThunk,
            getOwnerForListAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default ownerSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  ownerSlice.actions;
