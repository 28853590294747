// ** Reducers Imports
import auth from './pagesSlices/authSlice'
import model from './layoutSlices/modelSlice'
import owner from './pagesSlices/ownerSlice'
import group from './pagesSlices/groupSlice'
import user from './pagesSlices/userSlice'
import contact from './pagesSlices/contactSlice'
import address from './pagesSlices/addressSlice'
import classes from './pagesSlices/classesSlice'
import organization from './pagesSlices/organizationSlice'
import listings from './pagesSlices/listingsSlice'
import error from './errors/handleErrorsAndPayloads'
import { combineReducers } from '@reduxjs/toolkit'
import contents from './pagesSlices/contentSlice'

const rootReducer = combineReducers({
  listings,
  organization,
  address,
  classes,
  group,
  user,
  contact,
  owner,
  error,
  auth,
  model,
  contents
})

export default rootReducer
