import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
// import { getMyContentsAsyncThunk } from "../../../redux/pagesSlices/contentSlice";
// import ProviderErrorLoadingScroll from "../../ProviderErrorLoadingScroll";

import ImagesList from './imagesList'
import VideosList from './VideosList'
import AudiosList from './AudiosList'

import ImageCard from './cards/ImageCard'
import VideoCard from './cards/VideoCard'
import AudioCard from './cards/AudioCard'
import { getMyContentsAsyncThunk } from '../../redux/pagesSlices/contentSlice'
import ProviderErrorLoadingScroll from '../ProviderErrorLoadingScroll'

const ContentCards = (props) =>
  props.mimeType?.includes('image')
    ? (
      <ImageCard {...props} />
      )
    : props.mimeType?.includes('audio')
      ? (
        <AudioCard {...props} />
        )
      : (
        <VideoCard {...props} />
        )

function MyGallery () {
  const d = useDispatch()
  const [gellaryTab, setGellaryTab] = useState('all')

  useEffect(() => {
    d(
      getMyContentsAsyncThunk({
        ...(gellaryTab !== 'all' && { mimeType: gellaryTab }),
        limit: 12,
        page: 1,
        sortBy: 'updatedAt:desc'
      })
    )
  }, [gellaryTab])

  const ContentList = ({ dataKey }) => (
    <ProviderErrorLoadingScroll
      emtpyMessage='All Content Data Will Show Here!'
      reducer='contents'
      action='getMyContentsAsyncThunk'
      dataKey={dataKey}
      loadingIndicator='Loadingdata'
      Component={ContentCards}
      InfiniteScroll_props={{
        scrollableTarget: 'getMyContentsAsyncThunk_images_parent'
      }}
      loadMore={(e) => d(getMyContentsAsyncThunk({ ...e }))}
      asyncThunk={getMyContentsAsyncThunk}
    />
  )
  return (
    <div>
      <div className='btn-tab-outer mb-3'>
        <div className='row'>
          <div className='col-lg-7'>
            <Form.Control type='email' placeholder='Search gallery' />
          </div>
          <div className='col-lg-5'>
            <div className='my-gallery-tabs'>
              <button
                onClick={() => setGellaryTab('all')}
                className={`${gellaryTab === 'all' ? 'active' : null}`}
                style={{
                  width: 75,
                  height: 38,
                  border: 0
                }}
              >
                All
              </button>

              <button
                onClick={() => setGellaryTab('image')}
                className={`${gellaryTab === 'image' ? 'active' : null}`}
                style={{
                  width: 75,
                  height: 38,
                  border: 0
                }}
              >
                Images
              </button>

              <button
                onClick={() => setGellaryTab('video')}
                className={`${gellaryTab === 'video' ? 'active' : null}`}
                style={{
                  width: 75,
                  height: 38,
                  border: 0
                }}
              >
                video
              </button>
              <button
                onClick={() => setGellaryTab('audio')}
                className={`${gellaryTab === 'audio' ? 'active' : null}`}
                style={{
                  width: 75,
                  height: 38,
                  border: 0
                }}
              >
                Audio
              </button>
            </div>
          </div>
        </div>
      </div>
      {gellaryTab === 'all'
        ? (
          <div
            className='row'
            style={{
              overflow: 'auto'
            }}
            id='getMyContentsAsyncThunk_images_parent'
          >
            <ContentList dataKey='myContent' />
          </div>
          )
        : gellaryTab === 'image'
          ? (
            <div className='row'>
              <ContentList dataKey='myImages' />
            </div>
            )
          : gellaryTab === 'video'
            ? (
              <div className='row'>
                <ContentList dataKey='myVideos' />
              </div>
              )
            : gellaryTab === 'audio'
              ? (
                <div className='row'>
                  <ContentList dataKey='myAudios' />
                </div>
                )
              : null}
    </div>
  )
}
export default React.memo(MyGallery)
