export const reduceString = (s, n) =>
  s?.length >= n ? s.substring(0, n) + '...' : s
export const parseUrlFromModelTrophy = (e) =>
  'https://content.ilmiyacdn.com/assets/TROPHIES/PNG/' + encodeURIComponent(e)
export const parseUrlFromModel = (data) => {
  if (!data || !data.bucket || !data.name) {
    return null // Return null if the required properties are not present
  }

  // Construct the link using the bucket and name properties
  const link = `https://content.ilmiyacdn.com/${data.name}`

  return link
}
