import React, { useEffect, useState } from "react";
import { Form, FormLabel } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { parseUrlFromModel } from "../helpers/asset";
import ConfirmAddOwner from "./modals/ConfirmAddOwner";
import {
  createOwnerAsyncThunk,
  updateOwnerAsyncThunk,
  // createUserRoleAsyncThunk
} from "../redux/pagesSlices/ownerSlice";
import { OwnerSchema, OwnerSchemaIn1 } from "./Validation";
import ErrorMassage from "./ErrorMassage.jsx";
import CustomButton from "./customComponents/CustomButton";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
// import formInitialValues from './formInitialValues'
import * as yup from "yup";
import { pick } from "../helpers/pick";
import { space } from "../constants";

const OwnerCreateAndUpdate = ({ onHide }) => {
  const [image, setImages] = useState(null);
  const [user, setUser] = useState(null);
  const [showUserModel, setShowUserModel] = useState(false);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState();
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.RightSidebarBase
  );
  const oldData = modelArgs.args?.member;
  const d = useDispatch();

  const authErr = useSelector(
    (state) => state.owner?.errorMessages?.createOwnerAsyncThunk
  );

  const createOwnerHandler = (data) => {
    const updateData = {};
    const hasValueChanged = (fieldName) => {
      return data[fieldName] !== initialValues[fieldName];
    };

    if (oldData?.user_id) {
      if (data.password) {
        updateData.password = data.password;
      } else {
        updateData.email = data.email;
      }
    }
    if (data.given_name || data.family_name) {
      updateData.displayName = data.given_name + " " + data.family_name;
    }
    // if (data.family_name) {
    // updateData.family_name = data.family_name;
    // }
    if (data.username) {
      updateData.username = data.username;
    }
    if (hasValueChanged("email") && data.email.trim() !== "") {
      updateData.email = data.email;
    } else {
      delete updateData.email;
    }

    if (data.password) {
      updateData.password = data.password;
    }
    if (data.connection) {
      updateData.connection = data.connection;
    }
    // if (data.roles) {
    if (hasValueChanged("roles")) {
      updateData.roles = data.roles;
    }
    if(data.given_name || data.family_name){
      updateData.family_name = data.family_name;
      updateData.given_name = data.given_name;
    }

    if (oldData?.user_id) {
      d(
        updateOwnerAsyncThunk({
          data: updateData,
          id: oldData?.user_id,
          callBack: () => {
            toast.success("Updated Owner Successfully");
            onHide();
          },
        })
      );
    } else {
      d(
        createOwnerAsyncThunk({
          // data,
          data: updateData,
          callBack: () => {
            toast.success("Create Owner Successfully!");
            onHide();
          },
          callBackOnError: (callBackOnError) => {
            if (callBackOnError?.response?.data?.user?.email) {
              setUser(callBackOnError?.response?.data?.user);
              setShowUserModel(true);
            }
          },
        })
      );
    }
  };

  const closeModel = () => {
    d(handleModel({ model: "RightSidebarBase", state: false }));
  };

  // role
  let typesRole = [
    { id: 1, name: "Admin" },
    // { id: 2, name: "Viewer" },
    { id: 2, name: "Editor" },
    { id: 3, name: "User" },
    // { id: 4, name: "Customer" },
  ];
  const optionsRoles =
    typesRole &&
    typesRole?.map((e) => {
      return { value: e.id, label: e.name };
    });

  // Define the Yup validation schema
  const OwnerSchemaIn = yup.object().shape({
    given_name: yup.string().required("First name is required"),
    connection: yup.string(),
    family_name: yup.string().required("Last name is required"),
    username: yup
      .string()
      .matches(/^\S*$/, "No spaces allowed in user name")
      .required("User name is required"),
    email: yup.string().email("Enter Valid Email"),
    // .required("Email is required"),

    password: oldData?.user_id
      ? yup.string().min(1, "Password must be at least 1 character long")
      : yup
          .string()
          .required("Password is required")
          .min(1, "Password must be at least 1 character long"),
  });

  const initialValues = {
    ...(oldData?.user_id
      ? {
          ...oldData,
          user_id: undefined,
        }
      : {
          given_name: "",
          username: "",
          email: "",
          password: "",
          connection: "Username-Password-Authentication",
          roles: ["Admin"],
          family_name: "",
          // isUpdate: false,
        }),
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={createOwnerHandler}
        validationSchema={OwnerSchemaIn}
        // validationSchema={() => OwnerSchemaIn1(oldData?.user_id)}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div
            className="create-course-sibebar createOwnerSidebar"
            id="createOwnerSidebar"
            name="createOwnerSidebar"
          >
            <h4
              className="heading ownerFormHeading"
              id="ownerFormHeading"
              name="ownerFormHeading"
            >
              {oldData?.user_id ? "Update Owner" : "Create Owner"}
            </h4>

            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    onChange={handleChange("given_name")}
                    onBlur={handleBlur("given_name")}
                    value={values?.given_name}
                    id="givenNameInput"
                    name="givenNameInput"
                    className="ownerInput"
                  />
                  <ErrorMassage
                    error={errors.given_name}
                    visible={touched.given_name}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    onChange={handleChange("family_name")}
                    onBlur={handleBlur("family_name")}
                    value={values?.family_name}
                    id="familyNameInput"
                    name="familyNameInput"
                    className="ownerInput"
                  />
                  <ErrorMassage
                    error={errors.family_name}
                    visible={touched.family_name}
                  />
                </Form.Group>
              </div>

              {/* {!oldData?.user_id && ( */}
              <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    // onChange={handleChange("username")}
                    onChange={(e) => {
                      const username = e.target.value;
                      const email = `${username}@${space}.myilmiya.com`;
                      handleChange({
                        target: {
                          name: "username",
                          value: username,
                        },
                      });
                      setFieldValue("email", email);
                    }}
                    onBlur={handleBlur("username")}
                    value={values?.username}
                    disabled={oldData?.user_id ? true : false}
                    className={oldData?.user_id ? "disable-field" : null}
                    id="userNameInput"
                    name="userNameInput"
                  />
                  <ErrorMassage
                    error={errors.username}
                    visible={touched.username}
                  />
                </Form.Group>
              </div>
              {/* )} */}
              <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Email"
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values?.email}
                    disabled={oldData?.user_id ? true : false}
                    className={oldData?.user_id ? "disable-field" : null}
                    id="emailInput"
                    name="emailInput"
                  />
                  <ErrorMassage
                    error={errors?.email}
                    visible={touched?.email}
                  />
                </Form.Group>
              </div>

              {!oldData?.user_id && (
                <div className="col-lg-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter Password"
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      value={values?.password}
                      id="passwordInput"
                      name="passwordInput"
                    />
                    <ErrorMassage
                      error={errors?.password}
                      visible={touched?.password}
                    />
                  </Form.Group>
                </div>
              )}

              {!oldData && (
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Role</Form.Label>
                  <Select
                    options={optionsRoles}
                    name="roles"
                    onInputChange={(e) => {
                      setSearch(e);
                    }}
                    onChange={(selectedOptions) => {
                      const selectedRoleNames = selectedOptions.map(
                        (option) => option.label
                      );
                      setFieldValue("roles", selectedRoleNames);
                    }}
                    value={optionsRoles.find(
                      (option) => option.label === values.roles
                    )}
                    onBlur={handleBlur("roles")}
                    isSearchable={false}
                    isMulti
                    id="roleInput"
                  />
                  <ErrorMassage error={errors.roles} visible={touched.roles} />
                </Form.Group>
              )}
            </div>

            <div className="bottom-btns">
              <button
                className="btn btn-light"
                id="cancelbutton"
                name="cancelbutton"
                onClick={() => closeModel()}
              >
                Cancel
              </button>

              <CustomButton
                type={"button"}
                reducer={"owner"}
                action={
                  oldData?.user_id
                    ? "updateOwnerAsyncThunk"
                    : "createOwnerAsyncThunk"
                }
                title={oldData?.user_id ? "Update" : "Save"}
                onClick={handleSubmit}
                loadingText={oldData?.user_id ? "Updating..." : "Creating..."}
                className="btn btn-primary"
                id="submitButton"
                name="submitButton"
              />
            </div>
          </div>
        )}
      </Formik>
      <ConfirmAddOwner
        user={user}
        show={showUserModel}
        handleClose={() => setShowUserModel(false)}
      />
    </>
  );
};

export default OwnerCreateAndUpdate;
