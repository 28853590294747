import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import { handleModel } from "../../../redux/layoutSlices/modelSlice.js";
import "./leftSidebarBase.scss";

import { icons, redirect_uris } from "../../../constants.js";

function LeftSidebarBase({ name, ...props }) {
  const customToken = localStorage.getItem("custom-token");

  const d = useDispatch();
  const state = useSelector(
    (state) => state.model?.modelState?.LeftSidebarBase
  );

  const modelsArgs = useSelector(
    (state) => state.model?.modelArgs?.LeftSidebarBase
  );
  const callBack = modelsArgs?.callBack;
  const closeModel = () => {
    if (callBack) callBack();
    d(handleModel({ model: "LeftSidebarBase", state: false }));
  };

  const options = [
    { name: "Studio", permissions: ["Admin", "Editor", "Owner"] },
    { name: "People", permissions: ["Owner", "Admin"] },
    { name: "Analytics", permissions: ["Owner"] },
  ];
  const [show, setShow] = useState(state);

  useEffect(() => {
    setShow(state);
  }, [state]);

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: show ? "block" : "none",
    zIndex: 1040,
  };

  return (
    <>
      <div
        style={overlayStyle}
        onClick={() => {
          closeModel();
        }}
      />
      <Offcanvas
        className="left-side-menu"
        show={state}
        onHide={closeModel}
        {...props}
        backdrop={false}
        placement="start"
      >
        <Offcanvas.Body>
          {options?.map((option) => (
            // {!user?.email.endsWith(".com") &&
            <a
              href={redirect_uris[option.name] + `?token=${customToken}`}
              key={option.name}
              target="_blank"
            >
              <div className="nav-item">
                {icons[option.name]}
                <h6>{option.name}</h6>
              </div>
            </a>
          ))}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default LeftSidebarBase;
