import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'
import { ApiRequests } from '../../service/ApiRequests'
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder
} from '../../helpers/detectError'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'

// Start Content Slices
/// ////////////////////////////////////////////////

export const getContentsAsyncThunk = createAsyncThunk(
  'content/getContentsAsyncThunk',
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getContents(params)
    return response?.data
  })
)

export const getMyContentsAsyncThunk = createAsyncThunk(
  'content/getMyContentsAsyncThunk',
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getUserContents(params)
    return response?.data
  })
)

export const getContentAsyncThunk = createAsyncThunk(
  'content/getContentAsyncThunk',
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getContent(id)
    return response?.data
  })
)

export const createContentAsyncThunk = createAsyncThunk(
  'content/createContentAsyncThunk',
  catchAsync(async ({ data, options, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.createContent({ data, options })
    toast.success('Content Created Successfully!')
    if (callBack) callBack(response.data)
    if (response.status === 201) {
      // const location = useLocation();
      // location.reload();
      // window.location.reload();
      // let rr= window.location.pathname;
      // location.reload(rr);
      // window.location.reload(false)
      // window.parent.location.href;
      // window.location.reload(false);
      // Router.reload(window.location.pathname);
    }

    // if (response.status === 201) {
    //   toast.success("Content Created Successfully!");
    //     let params = {};
    //     let state = getState().listings;
    //     if (state.search) params.name = state.search;
    //     if (state.order) params.sortBy = `name:${state.order}`;
    //     dispatch(getContentsAsyncThunk({ ...params }));
    //   } else {
    //     toast.error(response.error);
    //   }
    return response?.data
  })
)

export const updateContentAsyncThunk = createAsyncThunk(
  'content/updateContentAsyncThunk',
  catchAsync(async ({ id, data, callBack }, { dispatch }) => {
    const response = await ApiRequests.updateContent({ id, data })
    if (response.status === 200) {
      toast.success('Content Updated Successfully!')
      dispatch(getContentsAsyncThunk())
      callBack()
    } else {
      toast.error(response.error)
    }
    return response?.data
  })
)

export const deleteContentAsyncThunk = createAsyncThunk(
  'content/deleteContentAsyncThunk',
  catchAsync(async (id, { dispatch, getState }) => {
    const response = await ApiRequests.deleteContent(id)
    if (response.status === 204) {
      toast.success('Content Deleted')
      const params = {}
      const state = getState().listings

      if (state.search) params.name = state.search
      if (state.order) params.sortBy = `name:${state.order}`
      dispatch(getContentsAsyncThunk({ ...params }))
    } else {
      toast.error(response.error)
    }
    return response?.data
  })
)

/// ////////////////////////////////////////////////

const initialState = {
  // news states
  contents: {
    page: 1,
    results: [],
    totalPages: 1
  },
  //
  images: {
    page: 1,
    results: [],
    totalPages: 1
  },
  videos: {
    page: 1,
    results: [],
    totalPages: 1
  },
  audios: {
    page: 1,
    results: [],
    totalPages: 1
  },
  pdfs: {
    page: 1,
    results: [],
    totalPages: 1
  },

  // for gallery data
  myImages: {
    page: 1,
    results: [],
    totalPages: 1
  },
  myVideos: {
    page: 1,
    results: [],
    totalPages: 1
  },
  myAudios: {
    page: 1,
    results: [],
    totalPages: 1
  },
  myPdfs: {
    page: 1,
    results: [],
    totalPages: 1
  },

  myContent: {
    page: 1,
    results: [],
    totalPages: 1
  },
  // End gallery

  //
  content: '',
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: 'asce'
}

const contentSlice = createSlice({
  name: 'contents',
  initialState,
  reducers: {
    setSearchValue (state, action) {
      state.search = action.payload
    },
    setCategoryValue (state, action) {
      state.categoryId = action.payload
    },
    setOrderValue (state, action) {
      state.order = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    //

      .addCase(getContentsAsyncThunk.fulfilled, (state, action) => {
        if (action?.meta?.arg?.mimeType === 'image') {
          if (action.payload?.page > 1) {
            state.images = {
              ...action.payload,
              results: state?.images?.results.concat(action?.payload?.results)
            }
          } else {
            state.images = action.payload
          }
        } else if (action?.meta?.arg?.mimeType === 'audio') {
          if (action.payload?.page > 1) {
            state.audios = {
              ...action.payload,
              results: state?.audios?.results.concat(action?.payload?.results)
            }
          } else {
            state.audios = action.payload
          }
        } else if (action?.meta?.arg?.mimeType === 'application/pdf') {
          if (action.payload?.page > 1) {
            state.pdfs = {
              ...action.payload,
              results: state?.pdfs?.results.concat(action?.payload?.results)
            }
          } else {
            state.pdfs = action.payload
          }
        } else if (action?.meta?.arg?.mimeType === 'video') {
          if (action.payload?.page > 1) {
            state.videos = {
              ...action.payload,
              results: state?.videos?.results.concat(action?.payload?.results)
            }
          } else {
            state.videos = action.payload
          }
        }
        // console.log("action?.meta?.mimeType",action.meta)
      })

      .addCase(getMyContentsAsyncThunk.fulfilled, (state, action) => {
        if (action?.meta?.arg?.mimeType === 'image') {
          if (action.payload?.page > 1) {
            state.myImages = {
              ...action.payload,
              results: state?.myImages?.results.concat(
                action?.payload?.results
              )
            }
          } else {
            state.myImages = action.payload
          }
        } else if (action?.meta?.arg?.mimeType === 'audio') {
          if (action.payload?.page > 1) {
            state.myAudios = {
              ...action.payload,
              results: state?.myAudios?.results.concat(
                action?.payload?.results
              )
            }
          } else {
            state.myAudios = action.payload
          }
        } else if (action?.meta?.arg?.mimeType === 'application/pdf') {
          if (action.payload?.page > 1) {
            state.myPdfs = {
              ...action.payload,
              results: state?.myPdfs?.results.concat(action?.payload?.results)
            }
          } else {
            state.myPdfs = action.payload
          }
        } else if (action?.meta?.arg?.mimeType === 'video') {
          if (action.payload?.page > 1) {
            state.myVideos = {
              ...action.payload,
              results: state?.myVideos?.results.concat(
                action?.payload?.results
              )
            }
          } else {
            state.myVideos = action.payload
          }
        } else {
          if (action.payload?.page > 1) {
            state.myContent = {
              ...action.payload,
              results: state?.myContent?.results.concat(
                action?.payload?.results
              )
            }
          } else {
            state.myContent = action.payload
          }
        }
        // console.log("action?.meta?.mimeType",action)
      })

      .addCase(getContentAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.content = {
            ...action.payload,
            results: state?.content?.results.concat(action?.payload?.results)
          }
        } else {
          state.content = action.payload
        }
      })
      .addCase(deleteContentAsyncThunk.fulfilled, (state, action) => {
        // console.log("response>>", action.payload);
      })
      .addCase(createContentAsyncThunk.fulfilled, (state, action) => {
        state.categories = action.payload?.results
        // console.log("response>>", action.payload);
      })
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asyncthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getContentsAsyncThunk,
            getMyContentsAsyncThunk,
            getContentAsyncThunk,
            deleteContentAsyncThunk,
            createContentAsyncThunk
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      )
  }
})

export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  contentSlice.actions

export default contentSlice.reducer
