import React from 'react'

export default function ErrorMassage ({ error, visible }) {
  if (!error || !visible) {
    return null
  } else {
    return (
      <div>
        <h5 style={{ color: '#FF9494', fontSize: 14, letterSpacing: 0 }}>
          {error}
        </h5>
      </div>
    )
  }
}
