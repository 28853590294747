import React from "react";
import { useNavigate } from "react-router-dom";
import { redirect_uris } from "../constants";

export default function Unauthorized() {
    const navigate = useNavigate()
    return (
        <div className="error-div">
            <img src="https://i.pinimg.com/originals/33/42/e4/3342e4ba684ff017acff7382cad86c7f.jpg" />
            <h1>Unauthorized!</h1>
            <a href={redirect_uris.Login}>
                <button className="btn btn-primary">Go to Login</button>
            </a>
        </div>
    )
}