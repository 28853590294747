import React, { useState } from 'react'
import { Image } from 'react-bootstrap'
import {
  parseUrlFromModel,
  parseUrlFromModelPatches
} from '../../helpers/imageLoader.js'
import { RotatingLines } from 'react-loader-spinner'
import { sr } from 'date-fns/locale'

const ImageLazyLoader = ({
  src,
  imageStyle,
  imgClassName,
  lazzyImageStyle,
  imgHandeler,
  imgLoaderWidth,
  imgloaderStyle,
  title = true,
  parseUrl = true,
  parsePatchesUrl = false
}) => {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  // const defaultImgSrc = require("../../images/palceholder1.webp");
  const defaultImgSrc = require('../../images/logo.png')

  // const imageSrc = src ? parseUrlFromModel(src) : defaultImgSrc;

  const handleImageLoaded = () => {
    setLoading(false)
    setLoaded(true)
  }

  const handleImageError = () => {
    setLoading(false)
    setError(true)
  }

  return (
    <>
      {/* show loading when image on loading */}
      {loading && src && src !== null && (
        <div style={imgloaderStyle}>
          <div>
            <RotatingLines
              strokeColor='grey'
              strokeWidth='5'
              animationDuration='0.75'
              width={imgLoaderWidth || '96'}
              visible
            />
            {title && <h6>Load Image</h6>}
          </div>
        </div>
      )}

      {/* show defeult image when Error image loading */}
      {error && (
        <Image
          style={{ ...lazzyImageStyle, resize: 'stretch' }}
          src={defaultImgSrc}
        />
      )}

      {/* show defeult image when no image provided */}
      {src
        ? null
        : (
          <Image
            style={{ ...lazzyImageStyle, resize: 'stretch' }}
            src={defaultImgSrc}
          />
          )}
      <Image
        style={loaded ? imageStyle : { display: 'none' }}
        src={
          parseUrl
            ? parseUrlFromModel(src)
            : parsePatchesUrl
              ? parseUrlFromModelPatches(src)
              : src
        }
        onLoad={handleImageLoaded}
        onError={handleImageError}
        onClick={imgHandeler}
        className={imgClassName}
      />
    </>
  )
}

export default ImageLazyLoader
