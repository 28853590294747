import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Offcanvas from 'react-bootstrap/Offcanvas'
import OwnerCreateAndUpdate from '../../OwnerCreateAndUpdate.jsx'
import { handleModel } from '../../../redux/layoutSlices/modelSlice.js'
import ClientCreateAndUpdate from '../../ClientCreateAndUpdate.jsx'
import GroupCreateAndUpdate from '../../GroupCreateAndUpdate.jsx'
import StudentCreateAndUpdate from '../../StudentCreateAndUpdate.jsx'
import InviteCreateAndUpdate from '../../InviteCreateAndUpdate.jsx'

function RightSidebarBase ({ name, ...props }) {
  const d = useDispatch()
  const state = useSelector(
    (state) => state.model?.modelState?.RightSidebarBase
  )
  const modelsArgs = useSelector(
    (state) => state.model?.modelArgs?.RightSidebarBase
  )
  const callBack = modelsArgs?.callBack
  const closeModel = () => {
    if (callBack) callBack()
    d(handleModel({ model: 'RightSidebarBase', state: false }))
  }
  return (
    <>
      <Offcanvas show={state} onHide={closeModel} {...props} placement='end'>
        <Offcanvas.Body>
          {modelsArgs?.resource == 'Owner' && (
            <OwnerCreateAndUpdate onHide={closeModel} />
          )}
          {modelsArgs?.resource == 'Invite' && (
            <InviteCreateAndUpdate onHide={closeModel} />
          )}

          {modelsArgs?.resource == 'Contacts' && (
            <ClientCreateAndUpdate onHide={closeModel} />
          )}

          {modelsArgs?.resource == 'Users' && (
            <StudentCreateAndUpdate onHide={closeModel} />
          )}

          {modelsArgs?.resource == 'Groups' && (
            <GroupCreateAndUpdate onHide={closeModel} />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
export default RightSidebarBase
