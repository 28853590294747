import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";

// Start Organization Slices
///////////////////////////////////////////////////

export const getOrganizationsAsyncThunk = createAsyncThunk(
  "organization/getOrganizationsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOrganizations(params);
    return response?.data;
  })
);


export const getOrganizationsByIdsAsyncThunk = createAsyncThunk(
  "organization/getOrganizationsByIdsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOrganizationsWithCount(params);
    return response?.data;
  })
);



export const getOrganizationAsyncThunk = createAsyncThunk(
  "organization/getOrganizationAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getOrganization(id);
    return response?.data;
  })
);
export const getOrganizationForListAsyncThunk = createAsyncThunk(
  "organization/getOrganizationForListAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOrganizations(params);
    return response?.data;
  })
);


export const createOrganizationAsyncThunk = createAsyncThunk(
  "organization/createOrganizationAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState()
    const response = await ApiRequests.createOrganization(data);
    toast.success("Organization Created Successfully!");
    if (callBack) callBack();
    dispatch(getOrganizationsByIdsAsyncThunk({populate:"image,user_id", ...state.organizations?.paramsForThunk?.getOrganizationsByIdsAsyncThunk,page:1}))
    return response?.data;
  })
);


export const updateOrganizationAsyncThunk = createAsyncThunk(
  "organization/updateOrganizationAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState()
    // console.log(state.organizations?.paramsForThunk)
    const response = await ApiRequests.updateOrganization({ id, data });
    toast.success("Organization Updated Successfully!");
    if (callBack) callBack();
    dispatch(getOrganizationsByIdsAsyncThunk({ populate: "image,user_id", ...state.organizations?.paramsForThunk?.getOrganizationsByIdsAsyncThunk, page: 1 }))
    return response?.data;
  })
);


export const deleteOrganizationAsyncThunk = createAsyncThunk(
  "organization/deleteOrganizationAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteOrganization(id);
    if (response.status == 204) {
      toast.success("Organization Deleted");
      let params = {};
      // let state = getState().listings;
      // if (state.search) params.name = state.search;
      // if (state.order) params.sortBy = `name:${state.order}`;
      // dispatch(getOrganizationsAsyncThunk({ ...params, populate:"image,user_id" }));
    } else {
      toast.error(response.error);
    }
    return id;
  })
);


///////////////////////////////////////////////////


const initialState = {
  //news states
  organizations: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  organizationsCount: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  organizationsList: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const organizationSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getOrganizationsAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.organizations = {
            page: 1,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(getOrganizationsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.organizations = {
            ...action.payload,
            results: state?.organizations?.results.concat(action?.payload?.results),
          };
        } else {
          state.organizations = action.payload;
        }
      })
      .addCase(getOrganizationsByIdsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.organizationsCount = {
            ...action.payload,
            results: state?.organizationsCount?.results.concat(action?.payload?.results),
          };
        } else {
          state.organizationsCount = action.payload;
        }
      })

      .addCase(getOrganizationForListAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.organizationsList = {
            ...action.payload,
            results: state?.organizationsList?.results.concat(action?.payload?.results),
          };
        } else {
          state.organizationsList = action.payload;
        }
      })
      .addCase(getOrganizationAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.story = {
            ...action.payload,
            results: state?.story?.results.concat(action?.payload?.results),
          };
        } else {
          state.story = action.payload;
        }
      })
      .addCase(deleteOrganizationAsyncThunk.fulfilled, (state, action) => {
        state.organizations = {
          ...state.organizations,
          totalResults: state.organizations?.totalResults-1,
          results: state.organizations?.results.filter(e=>e.id!=action.payload)
        }
        state.organizationsCount = {
          ...state.organizationsCount,
          totalResults: state.organizationsCount?.totalResults-1,
          results: state.organizationsCount?.results.filter(e=>e.id!=action.payload)
        }
      })
      // .addCase(createOrganizationAsyncThunk.fulfilled, (state, action) => {
      //   state.organizations = {
      //     ...state.organizations,
      //     totalResults: state.organizations?.totalResults+1,
      //     results: [...state.organizations?.results, action.payload]
      //   }
      //   state.organizationsCount = {
      //     ...state.organizationsCount,
      //     totalResults: state.organizationsCount?.totalResults+1,
      //     results: [...state.organizationsCount?.results, action.payload]
      //   }
      // })
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getOrganizationsAsyncThunk,
            getOrganizationAsyncThunk,
            getOrganizationsByIdsAsyncThunk,
            deleteOrganizationAsyncThunk,
            createOrganizationAsyncThunk,
            getOrganizationForListAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default organizationSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  organizationSlice.actions;
