import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Start Contact Slices
///////////////////////////////////////////////////

export const getContactsAsyncThunk = createAsyncThunk(
  "contact/getContactsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOwners(params);
    return response?.data;
  })
);

export const getContactsByIdsAsyncThunk = createAsyncThunk(
  "contact/getContactsByIdsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOwner(params);
    return response?.data;
  })
);

export const getContactAsyncThunk = createAsyncThunk(
  "contact/getContactAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getOwner(id);
    return response?.data;
  })
);
export const getContactForListAsyncThunk = createAsyncThunk(
  "contact/getContactForListAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOwners(params);
    return response?.data;
  })
);

export const createContactAsyncThunk = createAsyncThunk(
  "contact/createContactAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.createOwner(data);
    if (response.status == 204) {
      toast.success("Contact Create Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `username:${state1.order}`;
    dispatch(
      getContactsAsyncThunk({
        ...params,
        populate: "user_id",
        role: "Customer",
      })
    );
    // dispatch(getContactsByIdsAsyncThunk({ ...state.contacts?.paramsForThunk?.getContactsByIdsAsyncThunk}))
    return response?.data;
  })
);

export const updateContactAsyncThunk = createAsyncThunk(
  "contact/updateContactAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    // console.log(state.contacts?.paramsForThunk)
    const response = await ApiRequests.updateOwner({ id, data });
    if (response.status == 204) {
      toast.success("Contact Updated Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `username:${state1.order}`;
    dispatch(
      getContactsAsyncThunk({
        ...params,
        populate: "user_id",
        role: "Customer",
      })
    );
    // dispatch(getContactsByIdsAsyncThunk({ populate: "image,user_id", ...state.contacts?.paramsForThunk?.getContactsByIdsAsyncThunk, page: 1 }))
    return response?.data;
  })
);

export const deleteContactAsyncThunk = createAsyncThunk(
  "contact/deleteContactAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteOwner(id);
    if (response.status == 204) {
      toast.success("Contact Deleted Successfully!");
      let params = {};
      let state = getState().listings;
      if (state.search) params.name = state.search;
      if (state.order) params.sortBy = `username:${state.order}`;
      dispatch(
        getContactsAsyncThunk({
          ...params,
          populate: "user_id",
          role: "Customer",
        })
      );
    } else {
      toast.error(response.error);
    }
    return id;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  contacts: {
    page: 1,
    users: [],
    totalPages: 1,
  },
  contactsCount: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  inviteContact: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  userExport: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  userRole: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  contactsList: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const contactSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getContactsAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.contacts = {
            page: 1,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(getContactsAsyncThunk.fulfilled, (state, action) => {
        console.log(
          "🚀 ~ file: contactSlice.js:209 ~ .addCase ~ action:",
          action?.payload
        );
        if (action.payload?.page > 1) {
          state.contacts = {
            ...action.payload,
            users: state?.contacts?.results.concat(action?.payload?.results),
          };
        } else {
          state.contacts = action.payload;
          // console.log("🚀 ~ file: contactSlice.js:170 ~ .addCase ~ on.payload:", action.payload)
        }
      })
      .addCase(getContactsByIdsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.contactsCount = {
            ...action.payload,
            results: state?.contactsCount?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.contactsCount = action.payload;
        }
      })

      .addCase(getContactForListAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.contactsList = {
            ...action.payload,
            results: state?.contactsList?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.contactsList = action.payload;
        }
      })
      .addCase(getContactAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.story = {
            ...action.payload,
            results: state?.story?.results.concat(action?.payload?.results),
          };
        } else {
          state.story = action.payload;
        }
      })
      .addCase(deleteContactAsyncThunk.fulfilled, (state, action) => {
        state.contacts = {
          ...state.contacts,
          totalResults: state.contacts?.totalResults - 1,
          results: state.contacts?.results.filter(
            (e) => e.id != action.payload
          ),
        };
        state.contactsCount = {
          ...state.contactsCount,
          totalResults: state.contactsCount?.totalResults - 1,
          results: state.contactsCount?.results.filter(
            (e) => e.id != action.payload
          ),
        };
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getContactsAsyncThunk,
            getContactAsyncThunk,
            getContactsByIdsAsyncThunk,
            deleteContactAsyncThunk,
            createContactAsyncThunk,
            updateContactAsyncThunk,
            getContactForListAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default contactSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  contactSlice.actions;
