import React, { useEffect } from 'react'

import Student from '../images/Student.svg'
import Guardians from '../images/Guardians.svg'
import Families from '../images/Families.svg'
import Status from '../images/Status.svg'
import { BiLinkExternal, BiChevronDown, BiUpArrowAlt } from 'react-icons/bi'
import { Card, Dropdown, ProgressBar } from 'react-bootstrap'
import filter_alt from '../images/filter_alt.svg'
import first from '../images/first.svg'
import second from '../images/second.svg'
import third from '../images/3rd.svg'
import trophi from '../images/trophi.svg'
import gold from '../images/Gold.png'
import { Link } from 'react-router-dom'
import { HiDotsHorizontal } from 'react-icons/hi'
import { BarChart } from '@mui/x-charts/BarChart'
import { Box } from '@mui/material'
import {
  getHighlightedCountsAsyncThunk,
  getTopClassTrophiesAsyncThunk,
  getTopFiveLeaderboardAsyncThunk
} from '../redux/pagesSlices/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { parseUrlFromModelTrophy } from '../helpers/asset'
import Loadingdata from '../components/loadingdata'
import { ChartsNoDataOverlay } from '@mui/x-charts/ChartsOverlay'
import NoDataFound from '../components/NoDataFound'
import SkeletonLoading from './skeletonLoading'
import { classSpace, redirect_uris } from '../constants'
import { Skateboarding } from '@mui/icons-material'
import SkeletonLoadingGrid from './skeletonLoadingGrid'

export default function Highlights () {
  const d = useDispatch()
  const customToken = localStorage.getItem('custom-token')
  const [search, setSearch] = React.useState('')
  const state = useSelector((state) => state.model?.modelState?.CreatSpace)
  const getTopFiveUser = useSelector((state) => state.user.topFiveUser)
  const loading = useSelector(
    (state) => state.user.loadings.getTopFiveLeaderboardAsyncThunk
  )
  const loading2 = useSelector(
    (state) => state.user.loadings.getTopClassTrophiesAsyncThunk
  )
  const loading3 = useSelector(
    (state) => state.user.loadings.getHighlightedCountsAsyncThunk
  )
  console.log('🚀 ~ Highlights ~ loading3:', loading3)
  const getHighlightedCounts = useSelector(
    (state) => state.user.highlightedCounts
  )
  const getTopClassTrophies = useSelector(
    (state) => state.user.topClassTrophies
  )

  useEffect(() => {
    d(getTopFiveLeaderboardAsyncThunk())
    d(getHighlightedCountsAsyncThunk())
    d(getTopClassTrophiesAsyncThunk())
  }, [])

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }
  const filteredUsers = Array.isArray(getTopFiveUser)
    ? getTopFiveUser.filter((user) =>
      user?.User.displayName.toLowerCase().includes(search.toLowerCase())
    )
    : []

  const topTrophyImage =
    getTopClassTrophies[0]?.highestTrophyName === 'Platinum'
      ? parseUrlFromModelTrophy('Platinum Trophy.png')
      : getTopClassTrophies[0]?.highestTrophyName === 'Silver'
        ? parseUrlFromModelTrophy('Silver Trophy.png')
        : getTopClassTrophies[0]?.highestTrophyName === 'Gold'
          ? parseUrlFromModelTrophy('Gold Trophy.png')
          : parseUrlFromModelTrophy('Bronze Trophy.png')

  const topTrophyName =
    getTopClassTrophies[0]?.highestTrophyName === 'Platinum'
      ? 'Platinum'
      : getTopClassTrophies[0]?.highestTrophyName === 'Silver'
        ? 'Silver'
        : getTopClassTrophies[0]?.highestTrophyName === 'Gold'
          ? 'Gold'
          : 'Bronze'
  const students = [
    {
      id: 1,
      name: 'Marvin McKinney',
      img: 'https://randomuser.me/api/portraits/men/1.jpg',
      class: '2nd',
      rank: first
    },
    {
      id: 2,
      name: 'Esther Howard',
      img: 'https://randomuser.me/api/portraits/women/2.jpg',
      class: '2nd',
      rank: second
    },
    {
      id: 3,
      name: 'Robert Fox',
      img: 'https://randomuser.me/api/portraits/men/3.jpg',
      class: '2nd',
      rank: third
    },
    {
      id: 4,
      name: 'Jenny Wilson',
      img: 'https://randomuser.me/api/portraits/women/4.jpg',
      class: '2nd',
      rank: 4
    },
    {
      id: 5,
      name: 'Jacob Jones',
      img: 'https://randomuser.me/api/portraits/men/5.jpg',
      class: '2nd',
      rank: 5
    }
  ]

  const trophyData = [
    {
      subject: 'Islamic Studies',
      score: 61,
      image: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    {
      subject: 'Mathematics',
      score: 58,
      image: 'https://randomuser.me/api/portraits/women/2.jpg'
    },
    {
      subject: 'Science',
      score: 55,
      image: 'https://randomuser.me/api/portraits/men/3.jpg'
    },
    {
      subject: 'English',
      score: 52,
      image: 'https://randomuser.me/api/portraits/women/4.jpg'
    },
    {
      subject: 'History',
      score: 50,
      image: 'https://randomuser.me/api/portraits/men/5.jpg'
    }
  ]

  const formatTime = (totalSeconds) => {
    const roundedSeconds = Math.round(totalSeconds); // Floating-point precision fix
    const minutes = Math.floor(roundedSeconds / 60); // 1 minute = 60 seconds
    const seconds = roundedSeconds % 60; // Remaining seconds
    return `${minutes}m ${seconds}s`;
  };

  return (
    <div className='highlights-page py-5'>
      <div className='container'>
        <h2>Highlights</h2>

        <div className='row g-3 mb-3 highligh-section'>
          <div className='col-md-4 d-flex'>
            <Card body className='w-100 h-100'>
              <div className='d-flex align-items-center'>
                <div className='icon-bg me-3 d-flex align-items-center justify-content-center rounded-circle'>
                  <img src={Student} alt='' />
                </div>
                <span className='name flex-grow-1'>Total Students</span>
                <a
                  href={
                    redirect_uris.People +
                    `?token=${customToken}&space_id=${classSpace().class_id}`
                  }
                  target='blank'
                  className='text-decoration-none'
                >
                  <BiLinkExternal className=' mx-2 fs-4 text-dark' />
                </a>
              </div>
              {loading3
                ? (
                  <SkeletonLoadingGrid />
                  )
                : (
                  <div className='number py-3 fs-3 text-center'>
                    {getHighlightedCounts?.studentsCounts}
                  </div>
                  )}
            </Card>
          </div>
          <div className='col-md-4 d-flex'>
            <Card body className='w-100 h-100'>
              <div className='d-flex align-items-center'>
                <div className='icon-bg me-3 d-flex align-items-center justify-content-center rounded-circle'>
                  <img src={Guardians} alt='' />
                </div>
                <span className='name flex-grow-1'>Total Families</span>
                <a
                  href={
                    redirect_uris.People +
                    'family' +
                    `?token=${customToken}&space_id=${classSpace().class_id}`
                  }
                  target='blank'
                  className='text-decoration-none'
                >
                  <BiLinkExternal className=' mx-2 fs-4 text-dark' />
                </a>
              </div>
              {loading3
                ? (
                  <SkeletonLoadingGrid />
                  )
                : (
                  <div className='number py-3 fs-3 text-center'>
                    {getHighlightedCounts?.familyCount}
                  </div>
                  )}
            </Card>
          </div>
          <div className='col-md-4 d-flex'>
            <Card body className='w-100 h-100'>
              <div className='d-flex align-items-center'>
                <div className='icon-bg me-3 d-flex align-items-center justify-content-center rounded-circle'>
                  <img src={Families} alt='' style={{ width: '25px' }} />
                </div>
                <span className='name flex-grow-1'>Total Guardians</span>
                <a
                  href={
                    redirect_uris.People +
                    'guardians' +
                    `?token=${customToken}&space_id=${classSpace().class_id}`
                  }
                  target='blank'
                  className='text-decoration-none'
                >
                  <BiLinkExternal className=' mx-2 fs-4 text-dark' />
                </a>
              </div>
              {loading3
                ? (
                  <SkeletonLoadingGrid />
                  )
                : (
                  <div className='number py-3 fs-3 text-center'>
                    {getHighlightedCounts?.guardianCounts}
                  </div>
                  )}
            </Card>
          </div>
        </div>

        {/* <Card className="student-activity-card">
          <Card.Header
            as="h5"
            className="d-flex justify-content-between align-items-center"
          >
            <span className="heading">Students Activity</span>
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                {new Date().toLocaleString("default", {
                  month: "long",
                  year: "numeric",
                })}{" "}
                <BiChevronDown />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>January 2024</Dropdown.Item>
                <Dropdown.Item>February 2024</Dropdown.Item>
                <Dropdown.Item>March 2024</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Card.Header>
          <Card.Body>
            <Box sx={{ width: '100%', height: '100%', minHeight: 300 }}>
              <BarChart
                series={[
                  {
                    data: [
                      35, 44, 24, 34, 28, 39, 42, 31, 37, 45, 22, 33, 29, 40,
                    ],
                  },
                ]}
                xAxis={[
                  {
                    data: [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                      "Jan",
                      "Feb",
                    ],
                    scaleType: "band",
                  },
                ]}
                margin={{ top: 10, bottom: 50, left: 40, right: 10 }}
                barSize={30}
                slotProps={{
                  bar: {
                    rx: 8,
                    ry: 8,
                  },
                }}
                sx={{
                  '& .MuiChartsAxis-tickLabel': {
                    fontSize: {
                      xs: '0.7rem',
                      sm: '0.8rem',
                      md: '0.9rem',
                    },
                  },
                  '& .MuiChartsAxis-label': {
                    fontSize: {
                      xs: '0.8rem',
                      sm: '0.9rem',
                      md: '1rem',
                    },
                  },
                }}
                colors={["#1976d2"]}
              />
            </Box>
          </Card.Body>
        </Card> */}

        {/* <div className="row g-3 assesment">
          <div className="col-md-4">
            <Card body className="custom-card">
              <div className="d-flex align-items-center justify-content-between">
                <span className="ms-2 title">Students Online</span>
                <div className="icon d-flex align-items-center justify-content-center">
                  <img src={Status} alt="" />
                </div>
              </div>
              <span className="ms-2 count">50,000</span>
              <div className="b-block mt-4">
                <span className="text-end d-block bar-data">66/120</span>
                <ProgressBar now={61} />
              </div>
            </Card>
          </div>
          <div className="col-md-4">
            <Card body className="custom-card">
              <div className="d-flex align-items-center justify-content-between">
                <span className="ms-2 title">Assignments</span>
                <div className="icon d-flex align-items-center justify-content-center">
                  <img src={Student} alt="" />
                </div>
              </div>
              <span className="ms-2 count">66%</span>
              <div className="b-block mt-4">
                <span className="text-end d-block bar-data">66/120</span>
                <ProgressBar now={66} variant="success" />
              </div>
            </Card>
          </div>
          <div className="col-md-4">
            <Card body className="custom-card">
              <div className="d-flex align-items-center justify-content-between">
                <span className="ms-2 title">Students Online</span>
                <div className="icon d-flex align-items-center justify-content-center">
                  <img src={Student} alt="" />
                </div>
              </div>
              <span className="ms-2 count">50,000</span>
              <div className="b-block mt-4">
                <span className="text-end d-block bar-data">30/120</span>
                <ProgressBar now={30} variant="danger" />
              </div>
            </Card>
          </div>
        </div> */}

        <div className='last-section row g-3 h-100'>
          <div className='col-lg-8'>
            <div className='leader-board h-100'>
              <div className='d-flex justify-content-between align-items-center header'>
                <span className='leader-board-title'>Leader Board</span>
                <a
                  href={
                    redirect_uris.Analytics +
                    'leaderboard' +
                    `?token=${customToken}&space_id=${classSpace().class_id}`
                  }
                  target='blank'
                  className='text-decoration-none'
                >
                  <BiLinkExternal className='fs-4 text-dark' />
                </a>
              </div>

              <div className='row student-data-title py-2 g-0'>
                <div className='col-8'>
                  <span className='p-1 mx-2'>Rank</span>
                  <span className='p-1 mx-2'>Name</span>
                </div>
                <div className='col-2 text-end'>
                  <span>Time</span>
                  <BiUpArrowAlt
                    className='ms-2'
                    style={{ fontSize: '24px', color: '#DEE1E6' }}
                  />
                </div>
                <div className='col-2 text-end '>
                  <span className='px-2'>Coin</span>
                </div>
              </div>

              <div className='filter-students d-flex'>
                <img src={filter_alt} alt='' className='img-fluid mx-2' />
                <input
                  type='text'
                  placeholder='Filter Students'
                  value={search}
                  onChange={handleSearch}
                />
              </div>

              {loading
                ? (
                  <SkeletonLoading />
                  )
                : (
                  <ul className='list-unstyled'>
                    {filteredUsers?.length === 0
                      ? (
                        <NoDataFound />
                        )
                      : (
                        <>
                          {filteredUsers.length > 0 &&
                        filteredUsers?.map((user, index) => (
                          <li key={user.user_id} className='w-100'>
                            <div className='student-card d-flex align-items-center'>
                              <div className='row w-100 align-items-center'>
                                <div className='col-8 d-flex align-items-center'>
                                  <div className='student_img_wrapper d-flex align-items-center justify-content-center mx-3'>
                                    {user.userRank &&
                                    typeof user.userRank !== 'number'
                                      ? (
                                        <img
                                          src={user.userRank}
                                          className='w-24 img-fluid rounded-circle'
                                          alt={`Rank ${user?.userRank}`}
                                        />
                                        )
                                      : (
                                        <span>{user.userRank}</span>
                                        )}
                                  </div>
                                  <div className='d-flex align-items-center'>
                                    <img
                                      src={user.User.photoURL}
                                      className='img-fluid me-3 rounded-circle w-36'
                                      alt={user.User.displayName}
                                    />
                                    <span className='name'>
                                      {user.User.displayName}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-2 d-flex align-items-center justify-content-start">
                                  <p className="grade m-0 px-2">
                                    {formatTime(user?.totalTimeTaken)}
                                  </p>
                                </div>
                                <div className='col-2 d-flex align-items-center justify-content-end'>
                                  <span>{user?.totalObtainedMarks}</span>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                        </>
                        )}
                  </ul>
                  )}
            </div>
          </div>
          <div className='col-lg-4 h-100'>
            <div className='trophies'>
              <div className='d-flex align-items-center header'>
                <div className='img-container d-flex align-items-center justify-content-center'>
                  <img src={topTrophyImage} height='60' width='60' alt='' />
                </div>
                <div className='header-text-container'>
                  <div className='title'>Trophies</div>
                  <div>
                    <img src={topTrophyImage} alt='' className='me-2' />{' '}
                    <span>{topTrophyName}</span>
                  </div>
                </div>
              </div>
              <div className='trophies-list-header d-flex justify-content-between'>
                <div>Name</div>
                <div>Trophy</div>
              </div>
              {getTopClassTrophies.length === 0
                ? (
                  <NoDataFound />
                  )
                : (
                  <>
                    {loading2
                      ? (
                        <SkeletonLoading />
                        )
                      : (
                        <>
                          {getTopClassTrophies?.length > 0 &&
                        getTopClassTrophies?.map((trophy, index) => (
                          <Card key={index} className='mt-3 trophies-card'>
                            <Card.Body>
                              <div className='row d-flex align-items-center justify-content-between'>
                                <div className='col-8 d-flex gap-1 align-items-center justify-content-start'>
                                  <img
                                    src={trophy?.photoURL}
                                    alt='Profile'
                                    className='firstimg me-2'
                                  />
                                  <span className='subject'>
                                    {trophy?.displayName}
                                  </span>
                                </div>
                                <div className='col-4 align-items-center d-flex gap-2 justify-content-end'>
                                  <span className='number'>
                                    {trophy?.totalTrophies}
                                  </span>
                                  <div className='img-wrapper d-inline-flex align-items-center justify-content-center'>
                                    <img
                                      src={
                                        trophy?.highestTrophyName === 'Platinum'
                                          ? parseUrlFromModelTrophy(
                                            'Platinum Trophy.png'
                                          )
                                          : trophy?.highestTrophyName ===
                                              'Silver'
                                            ? parseUrlFromModelTrophy(
                                              'Silver Trophy.png'
                                            )
                                            : trophy?.highestTrophyName ===
                                                'Gold'
                                              ? parseUrlFromModelTrophy(
                                                'Gold Trophy.png'
                                              )
                                              : parseUrlFromModelTrophy(
                                                'Bronze Trophy.png'
                                              )
                                      }
                                      alt='Gold Trophy'
                                    />
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        ))}
                        </>
                        )}
                  </>
                  )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
