import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Nav, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleModel } from "../../redux/layoutSlices/modelSlice";

export default function ExportUserModel() {
  const d = useDispatch();
  const state = useSelector(
    (state) => state.model?.modelState?.openImportFailUserListModel
  );
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.openImportFailUserListModel
  );

  // close model
  const closeModel = () =>
    d(handleModel({ model: "openImportFailUserListModel", state: false }));

  return (
    <Modal
      animation={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={state}
      onHide={closeModel}
    >
      <Modal.Body className="invite-model">
        <h3>Import Users Failed</h3>
        {modelArgs?.args?.data?.map((item, i) => (
          <div
            key={i}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <h2 style={{ margin: 0, marginRight: "10px" }}>Reason:</h2>
              <p style={{ margin: 0 }}>{item?.errors[0]?.code}</p>
            </div>
            <div>
              <h2 style={{ margin: 0 }}>Which User:</h2>
              <p style={{ margin: 0 }}>Username: {item?.user?.username}</p>
              <p style={{ margin: 0 }}>Email: {item?.user?.email}</p>
            </div>
          </div>
        ))}
        <button className="btn btn-primary" onClick={closeModel}>Close</button>
      </Modal.Body>
    </Modal>
  );
}
