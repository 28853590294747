import React, { useEffect, useRef, useState } from "react";
import Badge from "react-bootstrap/Badge";
// import AddHomeIcon from "@mui/icons-material/AddHome";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
// import SupportOutlinedIcon from "@mui/icons-material/SupportOutlined";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import AOS from "aos";
// import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

import "./Header.scss";
import "aos/dist/aos.css";
import { auth } from "../../../config/firebase";
import { ApiRequests } from "../../../service/ApiRequests";

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { handleModel } from "../../../redux/layoutSlices/modelSlice";
import { useDispatch, useSelector } from "react-redux";
// import { InboxOutlined } from "@mui/icons-material";
import DatalistInput from "react-datalist-input";
import { redirect_uri, redirect_uris } from "../../../constants";
import { getAuth, signOut } from "firebase/auth";

const Header = ({ handleSearch, navLink, navIcon }) => {
  const { currentUser: user } = getAuth();
  const location = useLocation();
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef(null); // Create a ref for the input element
  const searchBarRef = useRef(null); // Create a ref for the search bar component

  useEffect(() => {
    if (showInput) {
      inputRef?.current?.focus(); // Focus on the input element when showInput is true
    }
  }, [showInput]);

  useEffect(() => {
    // Add event listener to handle clicks outside the search bar component
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowInput(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const d = useDispatch();
  const openLeftSidebar = () => {
    d(
      handleModel({
        model: "LeftSidebarBase",
        state: true,
        args: { resource: "leftSidebar" },
      })
    );
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} key={props}>
      {props}
    </Tooltip>
  );
  const closeLeftSidebar = () => {
    d(
      handleModel({
        model: "LeftSidebarBase",
        state: false,
        args: { resource: "leftSidebar" },
      })
    );
  };

  const modelState = useSelector((s) => s?.model?.modelState?.LeftSidebarBase);
  // console.log("localStorage.getItem('space')?.branding?.logo_url", JSON.parse(localStorage.getItem('space'))?.branding?.logo_url);

  let domainName = window.location.pathname.split("/");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // console.log("🚀 ~ file: index.jsx:96 ~ Header ~ screenWidth:", screenWidth);
  const [showDropdown, setShowDropdown] = useState(false);
  // console.log("🚀 ~ file: index.jsx:98 ~ Header ~ showDropdown:", showDropdown);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Check if the screen width is less than 700px
    if (screenWidth < 700) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  }, [screenWidth]);

  return (
    <div className="auth-body-outer">
      <div className="auth-header">
        <div className="header-row">
          <div className="left-nav">
            {modelState == false ? (
              <div className="menu-icon" onClick={openLeftSidebar}>
                <MenuOutlinedIcon />
              </div>
            ) : (
              <div className="menu-icon" onClick={closeLeftSidebar}>
                <MenuOutlinedIcon />
              </div>
            )}

            <div className="logo">
              {/* <img src={require("../../../images/logo.png")} /> */}
              <img
                src={
                  JSON.parse(localStorage.getItem("space"))?.branding?.logo_url
                }
              />
            </div>

            <div className="nav-item">
              <span className="bredcrumbb">
                {domainName[1]?.charAt(0)?.toUpperCase() +
                  domainName[1]?.slice(1)}
              </span>
            </div>
            <div className="header-top-nav">
              {navLink?.length
                ? navLink?.map((link, i) => {
                    if (screenWidth < 700 && i >= 0) {
                      return null;
                    }

                    return (
                      <Link to={link?.path} key={i}>
                        <div className="nav-item">
                          <div
                            className={`nav-link  ${
                              location?.pathname == link?.path ? "active" : ""
                            }`}
                          >
                            <span>{link?.name}</span>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                : null}

              {showDropdown && (
                <Dropdown
                  className="more Dropdown-Button"
                  id="More-Btn-Id"
                  name="More-Button"
                >
                  <Dropdown.Toggle
                    variant="success"
                    className="filer"
                    style={{
                      background: "none",
                      color: "black",
                      border: "none",
                      fontSize: "15px",
                      width: "100px",
                    }}
                    id="dropdown-basic"
                  >
                    More
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {navLink?.length &&
                      navLink?.map((link, i) => {
                        if (screenWidth >= 700 || i < 0) {
                          return null;
                        }

                        return (
                          <Dropdown.Item key={i}>
                            <Link to={link?.path}>
                              <div
                                style={{ height: "40px" }}
                                className="nav-link"
                                id="Dropdown-Id"
                                name="Dropdown-Link"
                              >
                                <span className="toe">{link?.name}</span>
                              </div>
                            </Link>
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            {/* <Link to="/">
              <div className="nav-item">
                <div
                  className={`nav-link  ${splitLocation[1] === "" ? "active" : ""
                    }`}
                >
                  <span>
                    Owner
                  </span>
                </div>
              </div>
            </Link>

            <Link to="/contacts">
              <div className="nav-item">
                <div
                  className={`nav-link  ${splitLocation[1] === "contacts" ? "active" : ""
                    }`}
                >
                  <span>
                    Contacts
                  </span>
                </div>
              </div>
            </Link>

        */}
          </div>

          <div className="right-nav">
            {/* <div className="nav-item">
              <div className="nav-link">
                <div className="search-bar">
                  {showInput && (
                    <DatalistInput
                      placeholder="Search Here"
                      onInput={(e) => handleSearch(e.target.value)}
                      items={[]}
                    />
                  )}
                  <SearchOutlinedIcon onClick={handleSearchIconClick} />
                </div>
              </div>
            </div> */}

            {/* {navIcon?.length &&
              navIcon?.map((icon, i) => {
                return (
                  <div className="nav-item">
                    <div className="nav-link">{icon}</div>
                  </div>
                );
              })} */}
            {/* <div className="nav-item">
              <div className="nav-link">
                <SupportOutlinedIcon />
              </div>
            </div>

            <div className="nav-item">
              <div className="nav-link">
                <div className="inbox-notification">
                  <InboxOutlinedIcon />
                  <Badge bg="danger">2</Badge>
                </div>
              </div>
            </div> */}

            <div className="nav-item">
              <Dropdown className="user-drop-down">
                <Dropdown.Toggle id="dropdown-basic">
                  <div className="user-profile">
                    <img
                      src={
                        user?.photoURL ?? require("../../../images/avatar.png")
                      }
                    />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="user-info">
                    {/* <h6>{user?.name}</h6> */}

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip(`${user?.displayName}`)}
                    >
                      <h6>{user?.displayName}</h6>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip(`${user?.email}`)}
                    >
                      <p>
                        {user?.email.length >= 20
                          ? user?.email.substring(0, 20)
                          : user?.email}
                      </p>
                    </OverlayTrigger>
                  </div>
                  <Dropdown.Item
                    className="people-dropdown-items"
                    onClick={() => {
                      signOut(auth).then(async () => {
                        await ApiRequests.revokeToken();
                        localStorage.clear();
                        window.location.href = redirect_uris.Login + "?logout=true";
                      });
                    }}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
