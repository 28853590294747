import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
import { getCollectionsAsyncThunk } from "./ownerSlice";

// Start Collection Slices
///////////////////////////////////////////////////

// export const getCollectionsAsyncThunk = createAsyncThunk(
//   "collection/getCollectionsAsyncThunk",
//   catchAsync(async (params, _) => {
//     // const response = await ApiRequests.getAssets(filterparams);
//     const response = await ApiRequests.getCollections(params);
//     return response?.data;
//   })
// );


// export const getCollectionAsyncThunk = createAsyncThunk(
//   "collection/getCollectionAsyncThunk",
//   catchAsync(async (params, _) => {
//     // const response = await ApiRequests.getAssets(filterparams);
//     const response = await ApiRequests.getCollection(params);
//     return response?.data;
//   })
// );

// export const updateCollectionAsyncThunk = createAsyncThunk(
//   "collection/updateCollectionAsyncThunk",
//   catchAsync(async ({ id, data, callBack }, _) => {
//     const response = await ApiRequests.updateCollection({ id, data });
//     toast.success("Collection Updated Successfully!");
//     if (callBack) callBack();
//     return response?.data;
//   })
// );


// export const deleteCollectionAsyncThunk = createAsyncThunk(
//   "collection/deleteCollectionAsyncThunk",
//   catchAsync(async (id, { dispatch, getState }) => {
//     // const response = await ApiRequests.getAssets(filterparams);
//     const response = await ApiRequests.deleteCollection(id);
//     if (response.status == 204) {
//       toast.success("Collection Deleted");
//       let params = {};
//       let state = getState().listings;
//       if (state.category && state.category != "all")
//         params.category = state.category;
//       if (state.search) params.name = state.search;
//       if (state.order) params.sortBy = `name:${state.order}`;
//       dispatch(getCollectionsAsyncThunk({ ...params, populate:"image,user_id" }));
//     } else {
//       toast.error(response.error);
//     }
//     return response?.data;
//   })
// );

// export const deleteCollectionAsyncThunk = createAsyncThunk(
//   "collection/deleteCollectionAsyncThunk",
//   catchAsync(async ({ id, callBack }) => {
//     const response = await ApiRequests.deleteCollection(id);
//     toast.success("Collection Deleted Successfully!");
//     if (callBack) callBack();
//     return response?.data;
//   })
// );

///////////////////////////////////////////////////

export const getListingsAsyncThunk = createAsyncThunk(
  "collection/getListingsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getAssets({ ...params });
    return response?.data;
  })
);
export const getListingAsyncThunk = createAsyncThunk(
  "collection/getListingAsyncThunk",
  catchAsync(async ({ id }, _) => {
    const response = await ApiRequests.getAsset(id);
    return response?.data;
  })
);
export const deleteListingAsyncThunk = createAsyncThunk(
  "collection/deleteListingAsyncThunk",
  catchAsync(async ({ id, collectionId, callBack }, { dispatch }) => {
    const response = await ApiRequests.deleteAsset(id);
    if (response.status == 204) {
      if (callBack) callBack();
      toast.success("Asset Deleted");
      dispatch(
        getListingsAsyncThunk({ collectionId: collectionId, limit: 1000000 })
      );
    } else {
      toast.error(response.error);
    }
    return response?.data;
  })
);

export const getCategorysAsyncThunk = createAsyncThunk(
  "collection/getCategorysAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getCategorys(params);
    return response?.data;
  })
);
export const createCategorysAsyncThunk = createAsyncThunk(
  "collection/createCategorysAsyncThunk",
  catchAsync(async ({ name, callBack }, { dispatch }) => {
    const response = await ApiRequests.createCategory({ name });
    if (response.status == 201) {
      dispatch(getCategorysAsyncThunk({ limit: 10000 }));
      callBack();
      toast.success("Category Created");
    } else {
      toast.error(response.error);
    }
    return response?.data;
  })
);

export const updateCategoryAsyncThunk = createAsyncThunk(
  "collection/updateCategoryAsyncThunk",
  catchAsync(async ({ id, name, callBack }, { dispatch }) => {
    const response = await ApiRequests.updateCategory({ id, name });
    if (response.status == 200) {
      dispatch(getCategorysAsyncThunk({ limit: 10000 }));
      toast.success("Category updated");
      callBack();
    } else {
      toast.error(response.error);
    }
    return response?.data;
  })
);

export const deleteCategoryAsyncThunk = createAsyncThunk(
  "collection/deleteCategoryAsyncThunk",
  catchAsync(async (params, { dispatch }) => {
    const response = await ApiRequests.deleteCategory(params);
    if (response.status == 204) {
      dispatch(getCategorysAsyncThunk({ limit: 10000 }));
      toast.success("Category deleted");
    } else {
      toast.error(response.error);
    }
    return response?.data;
  })
);


///////////////////////////////////////////////////


const initialState = {
  //news states
  collections: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const blogSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getListingsAsyncThunk.pending, (state, action) => {
        // code here
        if (action.meta.arg?.page == 1) {
          state.assets = { ...state.assets, results: [], totalResults: 0 };
        }
      })
      .addCase(getListingsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.assets = {
            ...action.payload,
            results: state?.assets?.results.concat(action?.payload?.results),
          };
        } else {
          state.assets = action.payload;
        }
      })
      .addCase(getListingAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.asset = {
            ...action.payload,
            results: state?.asset?.results.concat(action?.payload?.results),
          };
        } else {
          state.asset = action.payload;
        }
      })
      .addCase(deleteListingAsyncThunk.fulfilled, (state, action) => {
        
      })
      // .addCase(getCollectionsAsyncThunk.fulfilled, (state, action) => {
      //   if (action.payload?.page > 1) {
      //     state.collections = {
      //       ...action.payload,
      //       results: state?.collections?.results.concat(action?.payload?.results),
      //     };
      //   } else {
      //     state.collections = action.payload;
      //   }
      // })
      // .addCase(getCollectionAsyncThunk.fulfilled, (state, action) => {
      //   if (action.payload?.page > 1) {
      //     state.story = {
      //       ...action.payload,
      //       results: state?.story?.results.concat(action?.payload?.results),
      //     };
      //   } else {
      //     state.story = action.payload;
      //   }
      // })
      // .addCase(deleteCollectionAsyncThunk.fulfilled, (state, action) => {
        
      // })
      .addCase(getCategorysAsyncThunk.fulfilled, (state, action) => {
        state.categories = action.payload?.results;
        
      })
      .addCase(createCategorysAsyncThunk.fulfilled, (state, action) => {
        state.categories = action.payload?.results;
        
      })
      .addCase(deleteCategoryAsyncThunk.fulfilled, (state, action) => {
        
      })
      .addCase(updateCategoryAsyncThunk.fulfilled, (state, action) => {
        state.categories = action.payload?.results;
        
      })
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            // getCollectionsAsyncThunk,
            // deleteCollectionAsyncThunk,
            getListingsAsyncThunk,
            getListingAsyncThunk,
            deleteListingAsyncThunk,
            getCategorysAsyncThunk,
            createCategorysAsyncThunk,
            deleteCategoryAsyncThunk,
            updateCategoryAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default blogSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  blogSlice.actions;
