import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleModel } from "../redux/layoutSlices/modelSlice.js";
import {
  createInviteAdministrationAsyncThunk,
  getInviteAdministrationsAsyncThunk,
  updateInviteAdministrationAsyncThunk,
} from "../redux/pagesSlices/ownerSlice.js";
import ErrorMassage from "./ErrorMassage.jsx";
import CustomButton from "./customComponents/CustomButton.jsx";
import { Formik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
// import formInitialValues from './formInitialValues'
import * as yup from "yup";

const InviteCreateAndUpdate = ({ onHide }) => {
  const [search, setSearch] = useState("");
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.RightSidebarBase
  );
  const oldData = modelArgs.args?.member;
  const d = useDispatch();
  const authErr = useSelector(
    (state) => state.owner?.errorMessages?.createInviteAdministrationAsyncThunk
  );

  const createOwnerHandler = (data) => {
    const updateData = {};
    const hasValueChanged = (fieldName) => {
      return data[fieldName] !== initialValues[fieldName];
    };

    if (oldData?.user_id) {
      if (hasValueChanged("status")) {
        updateData.status = data.status;
      }
    }
    // if (data.given_name || data.family_name) {
    //   updateData.displayName = data.given_name + " " + data.family_name;
    // }
    // if (data.family_name) {
    // updateData.family_name = data.family_name;
    // }
    // if (data.username) {
    //   updateData.username = data.username;
    // }
    if (hasValueChanged("email") && data.email.trim() !== "") {
      updateData.email = data.email;
    } else {
      delete updateData.email;
    }

    // if (data.password) {
    //   updateData.password = data.password;
    // }
    // if (data.roles) {
    if (hasValueChanged("roles")) {
      updateData.roles = data.roles;
    }

    if (oldData?.user_id) {
      d(
        updateInviteAdministrationAsyncThunk({
          data: updateData,
          id: oldData?.user_id,
          callBack: () => {
            toast.success("Updated Owner Successfully");
            let params = { limit: 10, page: 1 };
            onHide();
            d(getInviteAdministrationsAsyncThunk({ ...params }));
          },
        })
      );
    } else {
      d(
        createInviteAdministrationAsyncThunk({
          // data,
          data: { ...updateData, type: "administrator" },
          callBack: () => {
            let params = { limit: 10, page: 1 };
            onHide();
            d(getInviteAdministrationsAsyncThunk({ ...params }));
            toast.success("Create Owner Successfully!");
          },
        })
      );
    }
  };

  const closeModel = () => {
    d(handleModel({ model: "RightSidebarBase", state: false }));
  };

  // role
  let typesRole = [
    { id: 1, name: "admin" },
    { id: 2, name: "owner" },
    { id: 3, name: "teacher" },
    { id: 4, name: "user" },
  ];
  const optionsRoles =
    typesRole &&
    typesRole?.map((e) => {
      return { value: e.id, label: e.name };
    });
  // ['pending', 'accepted', 'rejected']
  let statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "rejected", label: "Rejected" },
    { value: "accepted", label: "Accepted" },
  ];

  const optionsStatus =
    statusOptions &&
    statusOptions.map((e) => {
      return { value: e.value, label: e.label };
    });

  // Define the Yup validation schema
  const OwnerSchemaIn = yup.object().shape({
    email: yup
      .string()
      .email("Enter Valid Email")
      .required("Email is Required"),
    roles: yup.array().required("Email is Required"),
  });

  const initialValues = {
    ...(oldData?.user_id
      ? {
          ...oldData,
          status: oldData?.status,
          user_id: undefined,
        }
      : {
          roles: ["Owner"],
          email: "",
          expiry: 1,
        }),
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={createOwnerHandler}
        validationSchema={OwnerSchemaIn}
        // validationSchema={() => OwnerSchemaIn1(oldData?.user_id)}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div
            className="create-course-sibebar createOwnerSidebar"
            id="createOwnerSidebar"
            name="createOwnerSidebar"
          >
            <h4
              className="heading ownerFormHeading"
              id="ownerFormHeading"
              name="ownerFormHeading"
            >
              {oldData?.user_id ? "Update Invitation" : "Create Invitation"}
            </h4>

            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Email"
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values?.email}
                    disabled={oldData?.user_id ? true : false}
                    className={oldData?.user_id ? "disable-field" : null}
                    id="emailInput"
                    name="emailInput"
                  />
                  <ErrorMassage
                    error={errors?.email}
                    visible={touched?.email}
                  />
                </Form.Group>
              </div>
              {oldData?.user_id && (
                <div className="col-lg-12">
                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <Select
                      className="section-name"
                      name="status"
                      id="status"
                      placeholder="Choose Status"
                      value={optionsStatus.find(
                        (l) => l.value === values.status
                      )}
                      onChange={(selectedOption) => {
                        let event = {
                          target: {
                            name: "status",
                            value: selectedOption.value,
                          },
                        };
                        handleChange(event);
                      }}
                      onBlur={() => {
                        handleBlur({ target: { name: "status" } });
                      }}
                      options={optionsStatus}
                    />
                    <ErrorMassage
                      error={errors.status}
                      visible={touched.status}
                    />
                  </Form.Group>
                </div>
              )}
              {!oldData?.user_id && (
                <div className="col-lg-12">
                  <Form.Group className="mb-3">
                    <Form.Label>Expires In days</Form.Label>
                    <Form.Control
                      type="Number"
                      placeholder="Enter in days"
                      onChange={handleChange("expiry")}
                      onBlur={handleBlur("expiry")}
                      value={values?.expiry}
                      id="expiryInput"
                      name="expiryInput"
                    />
                    <ErrorMassage
                      error={errors?.expiry}
                      visible={touched?.expiry}
                    />
                  </Form.Group>
                </div>
              )}

              {!oldData && (
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Select
                    options={optionsRoles}
                    name="roles"
                    onInputChange={(e) => {
                      setSearch(e);
                    }}
                    onChange={(selectedOptions) => {
                      const selectedRoleNames = selectedOptions.map(
                        (option) => option.label
                      );
                      setFieldValue("roles", selectedRoleNames);
                    }}
                    value={optionsRoles.find(
                      (option) => option.label === values.roles
                    )}
                    onBlur={handleBlur("roles")}
                    isSearchable={false}
                    isMulti
                    id="roleInput"
                  />
                  <ErrorMassage error={errors.roles} visible={touched.roles} />
                </Form.Group>
              )}
            </div>

            <div className="bottom-btns">
              <button
                className="btn btn-light"
                id="cancelbutton"
                name="cancelbutton"
                onClick={() => closeModel()}
              >
                Cancel
              </button>

              <CustomButton
                type={"button"}
                reducer={"owner"}
                action={
                  oldData?.user_id
                    ? "updateInviteAdministrationAsyncThunk"
                    : "createInviteAdministrationAsyncThunk"
                }
                title={oldData?.user_id ? "Update" : "Save"}
                onClick={handleSubmit}
                loadingText={oldData?.user_id ? "Updating..." : "Creating..."}
                className="btn btn-primary"
                id="submitButton"
                name="submitButton"
              />
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default InviteCreateAndUpdate;
