import React, { useEffect, useState } from 'react'
import { Form, FormLabel } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { handleModel } from '../redux/layoutSlices/modelSlice'
import { parseUrlFromModel } from '../helpers/asset'
import Accordion from 'react-bootstrap/Accordion'
import {
  createOwnerAsyncThunk,
  updateOwnerAsyncThunk,
  createUserRoleAsyncThunk
} from '../redux/pagesSlices/ownerSlice'
import { ContactSchemaIn, OwnerSchema } from './Validation'
import ErrorMassage from './ErrorMassage.jsx'
import CustomButton from './customComponents/CustomButton'
import { Formik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
// import formInitialValues from './formInitialValues'
import * as yup from 'yup'
import {
  createContactAsyncThunk,
  updateContactAsyncThunk
} from '../redux/pagesSlices/contactSlice'
import PhoneInput from 'react-phone-number-input'

const ClientCreateAndUpdate = ({ onHide }) => {
  const [image, setImages] = useState(null)
  const [search, setSearch] = useState('')
  const [value, setValue] = useState()
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.RightSidebarBase
  )
  const oldData = modelArgs.args?.member
  const d = useDispatch()

  const authErr = useSelector(
    (state) => state.owner?.errorMessages?.createOwnerAsyncThunk
  )

  const createOwnerHandler = (data) => {
    if (oldData?.user_id) {
      d(
        updateContactAsyncThunk({
          data: {
            name: data.name,
            user_metadata: {
              phone: data.user_metadata.phone
            },
            password: data.password,
            connection: 'Username-Password-Authentication',
            nickname: data.nickname,
            given_name: data.given_name,
            family_name: data.family_name
          },
          id: oldData?.user_id,
          callBack: () => {
            toast.success('Updated Contact Successfully')
            onHide()
          }
        })
      )
    } else {
      d(
        createContactAsyncThunk({
          data,
          callBack: () => {
            toast.success('Create Contact Successfully!')
            onHide()
          }
        })
      )
    }
  }
  const closeModel = () => {
    // if (oldData?.callBack) oldData.callBack(oldData[oldData.type])
    d(handleModel({ model: 'RightSidebarBase', state: false }))
  }

  // role
  // let typesRole = [
  //   { id: 1, name: "User" },
  //   { id: 2, name: "Owner" },
  //   { id: 3, name: "Customer" },
  // ];
  // const options =
  //   typesRole &&
  //   typesRole?.map((e) => {
  //     return { value: e.id, label: e.name };
  //   });

  // space
  const typesSpace = [
    { id: 1, name: 'Ilmiya' },
    { id: 2, name: 'Aflah' }
  ]
  const typeSpaceOption =
    typesSpace &&
    typesSpace?.map((e) => {
      return { value: e.id, label: e.name }
    })

  // tags
  const typesTags = [
    { id: 1, name: 'English' },
    { id: 2, name: 'Maths' }
  ]
  const typeTagsOption =
    typesTags &&
    typesTags?.map((e) => {
      return { value: e.id, label: e.name }
    })

  // Define the Yup validation schema
  const OwnerSchemaIn = yup.object().shape({
    name: yup.string().required('First name is required'),
    username: yup
      .string()
      .required('Username is required')
      .matches(/^\S*$/, 'No spaces allowed in user name'),
    email: yup.string().email('Invalid email').required('Email is required'),

    password: oldData?.user_id
      ? yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
          'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
        )
        .min(8, 'Password must be at least 8 characters long')
      : yup
        .string()
        .required('Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
          'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
        )
        .min(8, 'Password must be at least 8 characters long'),

    user_metadata: yup.object().shape({
      phone: yup
        .string()
        // .matches(/^\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format XXX-XXX-XXXX')
        .required('Phone number is Required')
    }),
    // roles: yup.array().required("roles is Required"),

    connection: yup.string().required('Connection is Required'),
    nickname: yup.string().required('Last name is required'),
    given_name: yup.string().required('Given name is required'),
    family_name: yup.string().required('Family name is required')
    // roles: yup.array().required("roles is Required"),
  })

  const initialValues = {
    ...(oldData?.user_id
      ? {
          ...oldData,
          name: oldData.name,
          username: oldData?.username,
          email: oldData.email,
          password: oldData?.password,
          connection: 'Username-Password-Authentication',
          // connection: oldData?.identities[0]?.connection,
          // roles: oldData?.user_metadata?.roles,
          roles: ['Customer'],
          nickname: oldData.nickname,
          given_name: oldData.given_name,
          family_name: oldData.family_name,
          user_id: undefined
        }
      : {
          name: '',
          username: '',
          email: '',
          user_metadata: {
            phone: ''
          },
          password: '',
          connection: 'Username-Password-Authentication',
          roles: ['Customer'],
          nickname: '',
          given_name: '',
          family_name: ''
          // picture: ""
        })
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={createOwnerHandler}
        validationSchema={OwnerSchemaIn}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched
        }) => (
          <div className='create-course-sibebar'>
            <h4 className='heading'>
              {oldData?.user_id ? 'Update Contact' : 'Create Contact'}
            </h4>

            <div className='row'>
              <div className='col-lg-12'>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>username</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter username'
                    onChange={handleChange('username')}
                    onBlur={handleBlur('username')}
                    value={values?.username}
                    disabled={!!oldData?.user_id}
                    className={oldData?.user_id ? 'disable-field' : null}
                  />
                  <ErrorMassage
                    error={errors.username}
                    visible={touched.username}
                  />
                </Form.Group>
              </div>

              <div className='col-lg-12'>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Email'
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values?.email}
                  />
                  <ErrorMassage
                    error={errors?.email}
                    visible={touched?.email}
                  />
                </Form.Group>
              </div>

              <div className='col-lg-12'>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Phone No</Form.Label>
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry='US'
                    value={values?.user_metadata?.phone || ''}
                    onChange={(value) =>
                      setFieldValue('user_metadata.phone', value)}
                    onBlur={handleBlur}
                  />

                  {/* <PhoneInput
                   international
                   countryCallingCodeEditable={false}
                   defaultCountry="USA"
                    type="number"
                    placeholder="Enter Phone Number"
                    name="user_metadata.phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.user_metadata?.phone || ""}
                  /> */}
                  {/* <Form.Control
                    type="number"
                    placeholder="Enter Phone Number"
                    name="user_metadata.phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.user_metadata?.phone || ""}
                  /> */}
                  <ErrorMassage
                    error={errors?.user_metadata?.phone}
                    visible={touched?.user_metadata?.phone}
                  />
                </Form.Group>
              </div>

              <div className='col-lg-12'>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Password'
                    onChange={handleChange('password')}
                    onBlur={handleBlur('password')}
                    value={values?.password}
                  />
                  <ErrorMassage
                    error={errors?.password}
                    visible={touched?.password}
                  />
                </Form.Group>
              </div>

              {/* <div className="col-lg-12">

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Connection</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    placeholder="Enter Connection"
                    onChange={handleChange("connection")}
                    onBlur={handleBlur("connection")}
                    value={values?.connection}
                  />
                  <ErrorMassage error={errors?.connection} visible={touched?.connection} />
                </Form.Group>
              </div> */}

              {/* <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Roles</Form.Label>
                  <Select
                    options={options}
                    name="roles"
                    onInputChange={(e) => {
                      setSearch(e);
                    }}
                    onChange={(selectedOptions) => {
                      const selectedRoleNames = selectedOptions.map(
                        (option) => option.label
                      );
                      console.log(
                        "🚀 ~ file: OwnerCreateAndUpdate.jsx:254 ~ OwnerCreateAndUpdate ~ selectedRoleNames:",
                        selectedRoleNames
                      );
                      setFieldValue("roles", selectedRoleNames);
                    }}
                    value={options.find(
                      (option) => option.label === values.roles
                    )}
                    onBlur={handleBlur("roles")}
                    isSearchable={false}
                    isMulti
                  />
                  <ErrorMassage error={errors.roles} visible={touched.roles} />
                </Form.Group>
                {values.roles && (
                  <ul>
                    {values.roles.map((role, index) => (
                      <li key={index}>{role}</li>
                    ))}
                  </ul>
                )}
              </div> */}

              <div className='col-lg-6'>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter First Name'
                    onChange={handleChange('name')}
                    onBlur={handleBlur('name')}
                    value={values?.name}
                  />
                  <ErrorMassage error={errors.name} visible={touched.name} />
                </Form.Group>
              </div>

              <div className='col-lg-6'>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Last Name'
                    onChange={handleChange('nickname')}
                    onBlur={handleBlur('nickname')}
                    value={values?.nickname}
                  />
                  <ErrorMassage
                    error={errors.nickname}
                    visible={touched.nickname}
                  />
                </Form.Group>
              </div>

              <div className='col-lg-6'>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Given Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Given Name'
                    onChange={handleChange('given_name')}
                    onBlur={handleBlur('given_name')}
                    value={values?.given_name}
                  />
                  <ErrorMassage
                    error={errors.given_name}
                    visible={touched.given_name}
                  />
                </Form.Group>
              </div>

              <div className='col-lg-6'>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Family Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Family Name'
                    onChange={handleChange('family_name')}
                    onBlur={handleBlur('family_name')}
                    value={values?.family_name}
                  />
                  <ErrorMassage
                    error={errors.family_name}
                    visible={touched.family_name}
                  />
                </Form.Group>
              </div>

              {/* <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Grade</Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option>Select Garde</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>

                  <ErrorMassage error={errors.grade} visible={touched.grade} />
                </Form.Group>
              </div> */}

              {/* <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Select Date</Form.Label>
                  <Form.Control
                    type="date"
                  onChange={handleChange("date")}
                  onBlur={handleBlur("date")}
                  value={values?.date}
                  />
                  <ErrorMassage error={errors.date} visible={touched.date} />
                </Form.Group>
              </div> */}
            </div>

            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Attach Thumbnail</Form.Label>
              <div
                className="img-uploader"
                onClick={() => openContent(setFieldValue)}
              >

                  <div className="icon-box">
                    <i className="fa-regular fa-image"></i>
                  </div>
              </div>
              <ErrorMassage error={errors.image} visible={touched.image} />
            </Form.Group> */}

            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Phone No</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone Number"
                      onChange={handleChange("number")}
                      onBlur={handleBlur("number")}
                      value={values?.number}
                    />
                    <ErrorMassage error={errors?.number} visible={touched?.number} />
                  </Form.Group> */}

            {/* <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Actions</Accordion.Header>
                <Accordion.Body>

                  <div className="col-lg-12">

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Select Space</Form.Label>
                      <Select
                        options={typeSpaceOption}
                        placeholder={"Select Space"}
                        name="space"
                        onInputChange={(e) => {
                          setSearch(e);
                        }}
                        onChange={(selectedOptions) => {
                          const selectedRoleNames = selectedOptions.map((option) => option.label);
                          console.log("🚀 ~ file: OwnerCreateAndUpdate.jsx:254 ~ OwnerCreateAndUpdate ~ selectedRoleNames:", selectedRoleNames)
                          setFieldValue("space", selectedRoleNames);
                        }}
                        value={options.find((option) => option.label === values.space)}
                        onBlur={handleBlur("space")}
                        isSearchable={false}
                        isMulti
                      />
                      <ErrorMassage
                        error={errors.space}
                        visible={touched.space}
                      />
                    </Form.Group>
                    {values.space && (
                      <ul>
                        {values.space.map((space, index) => (
                          <li key={index}>{space}</li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="col-lg-12">

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Add Tags</Form.Label>
                      <Select
                        options={typeTagsOption}
                        placeholder={"Select Tags"}
                        name="tag"
                        onInputChange={(e) => {
                          setSearch(e);
                        }}
                        onChange={(selectedOptions) => {
                          const selectedRoleNames = selectedOptions.map((option) => option.label);
                          console.log("🚀 ~ file: OwnerCreateAndUpdate.jsx:254 ~ OwnerCreateAndUpdate ~ selectedRoleNames:", selectedRoleNames)
                          setFieldValue("tag", selectedRoleNames);
                        }}
                        value={options.find((option) => option.label === values.tag)}
                        onBlur={handleBlur("tag")}
                        isSearchable={false}
                        isMulti
                      />
                      <ErrorMassage
                        error={errors.tag}
                        visible={touched.tag}
                      />
                    </Form.Group>
                    {values.tag && (
                      <ul>
                        {values.tag.map((tag, index) => (
                          <li key={index}>{tag}</li>
                        ))}
                      </ul>
                    )}
                  </div>

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Action</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}

            <div className='bottom-btns'>
              <button className='btn btn-light' onClick={() => closeModel()}>
                Cancel
              </button>
              {/* <button
                onClick={() => handleSubmit()}
                className="btn btn-primary"
                type="submit"
              >
                Save
              </button> */}

              <CustomButton
                reducer='contact'
                action={
                  oldData?.user_id
                    ? 'updateContactAsyncThunk'
                    : 'createContactAsyncThunk'
                }
                title={oldData?.user_id ? 'Update' : 'Save'}
                onClick={handleSubmit}
                loadingText={oldData?.user_id ? 'Updating...' : 'Creating...'}
                className='btn btn-primary'
              />
            </div>
          </div>
        )}
      </Formik>
    </>
  )
}

export default ClientCreateAndUpdate
