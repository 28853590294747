import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";

// Start Address Slices
///////////////////////////////////////////////////

export const getAddresssAsyncThunk = createAsyncThunk(
  "address/getAddresssAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getAddresss(params);
    return response?.data;
  })
);


export const getAddresssByIdsAsyncThunk = createAsyncThunk(
  "address/getAddresssByIdsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getAddresssWithCount(params);
    return response?.data;
  })
);



export const getAddressAsyncThunk = createAsyncThunk(
  "address/getAddressAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getAddress(id);
    return response?.data;
  })
);
export const getAddressForListAsyncThunk = createAsyncThunk(
  "address/getAddressForListAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getAddresss(params);
    return response?.data;
  })
);


export const createAddressAsyncThunk = createAsyncThunk(
  "address/createAddressAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState()
    const response = await ApiRequests.createAddress(data);
    toast.success("Address Created Successfully!");
    if (callBack) callBack();
    // dispatch(getAddresssByIdsAsyncThunk({populate:"image,user_id", ...state.addresss?.paramsForThunk?.getAddresssByIdsAsyncThunk,page:1}))
    return response?.data;
  })
);


export const updateAddressAsyncThunk = createAsyncThunk(
  "address/updateAddressAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState()
    console.log(state.addresss?.paramsForThunk)
    const response = await ApiRequests.updateAddress({ id, data });
    toast.success("Address Updated Successfully!");
    if (callBack) callBack();
    // dispatch(getAddresssByIdsAsyncThunk({ populate: "image,user_id", ...state.addresss?.paramsForThunk?.getAddresssByIdsAsyncThunk, page: 1 }))
    return response?.data;
  })
);


export const deleteAddressAsyncThunk = createAsyncThunk(
  "address/deleteAddressAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteAddress(id);
    if (response.status == 204) {
      toast.success("Address Deleted");
      let params = {};
      // let state = getState().listings;
      // if (state.search) params.name = state.search;
      // if (state.order) params.sortBy = `name:${state.order}`;
      // dispatch(getAddresssAsyncThunk({ ...params, populate:"image,user_id" }));
    } else {
      toast.error(response.error);
    }
    return id;
  })
);


///////////////////////////////////////////////////


const initialState = {
  //news states
  addresss: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  addresssCount: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  addresssList: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const addressSlice = createSlice({
  name: "addresss",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getAddresssAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.addresss = {
            page: 1,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(getAddresssAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.addresss = {
            ...action.payload,
            results: state?.addresss?.results.concat(action?.payload?.results),
          };
        } else {
          state.addresss = action.payload;
        }
      })
      .addCase(getAddresssByIdsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.addresssCount = {
            ...action.payload,
            results: state?.addresssCount?.results.concat(action?.payload?.results),
          };
        } else {
          state.addresssCount = action.payload;
        }
      })

      .addCase(getAddressForListAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.addresssList = {
            ...action.payload,
            results: state?.addresssList?.results.concat(action?.payload?.results),
          };
        } else {
          state.addresssList = action.payload;
        }
      })
      .addCase(getAddressAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.story = {
            ...action.payload,
            results: state?.story?.results.concat(action?.payload?.results),
          };
        } else {
          state.story = action.payload;
        }
      })
      .addCase(deleteAddressAsyncThunk.fulfilled, (state, action) => {
        state.addresss = {
          ...state.addresss,
          totalResults: state.addresss?.totalResults-1,
          results: state.addresss?.results.filter(e=>e.id!=action.payload)
        }
        state.addresssCount = {
          ...state.addresssCount,
          totalResults: state.addresssCount?.totalResults-1,
          results: state.addresssCount?.results.filter(e=>e.id!=action.payload)
        }
      })
      // .addCase(createAddressAsyncThunk.fulfilled, (state, action) => {
      //   state.addresss = {
      //     ...state.addresss,
      //     totalResults: state.addresss?.totalResults+1,
      //     results: [...state.addresss?.results, action.payload]
      //   }
      //   state.addresssCount = {
      //     ...state.addresssCount,
      //     totalResults: state.addresssCount?.totalResults+1,
      //     results: [...state.addresssCount?.results, action.payload]
      //   }
      // })
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getAddresssAsyncThunk,
            getAddressAsyncThunk,
            getAddresssByIdsAsyncThunk,
            deleteAddressAsyncThunk,
            createAddressAsyncThunk,
            getAddressForListAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default addressSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  addressSlice.actions;
