import { auth } from '../config/firebase'
import { redirect_uris } from '../constants'
import { ApiRequests } from '../service/ApiRequests'

export const logout = async (setLoading) => {
  try {
    setLoading(true) // Set loading to true when logout starts
    await auth.signOut()
    await ApiRequests.revokeToken()
    localStorage.clear()
    window.location.href = redirect_uris.Login + '?logout=true'
  } catch (error) {
    console.error('Logout error:', error)
  } finally {
    setLoading(false) // Set loading to false after logout completes
  }
}
