import Countly from '../config/countly';

export const countUserEvent = (eventName, eventData = {}) => {
  try {
    Countly.add_event({
      key: "Console: " + eventName,
      count: 1,
      segmentation: eventData
    });
  } catch (error) {
    console.error('Error while countly storing events:', error);
  }
};

export const Countly_Session_Start = async () => {
  // Countly.begin_session();
  // Countly.session_duration(50);
  Countly.q.push(['begin_session']);
}

export const Countly_Session_End = () => {
  Countly.q.push(['end_session']);
}
export const Countly_Track_Pageview = (pageName) => {
  Countly.q.push(['track_pageview', "Console->"+pageName]);
} 