import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Nav, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { handleModel } from '../../redux/layoutSlices/modelSlice'
import { Formik } from 'formik'
import ErrorMassage from '../ErrorMassage'
import {
  createUserExportAsyncThunk,
  createUserImportAsyncThunk
} from '../../redux/pagesSlices/ownerSlice'
import { toast } from 'react-toastify'
import { UserExportSchema, UserImportSchema } from '../Validation'
import Select from 'react-select'
import CustomButton from '../customComponents/CustomButton'
import { Link } from 'react-router-dom'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

export default function ExportUserModel () {
  const d = useDispatch()
  const state = useSelector(
    (state) => state.model?.modelState?.ExportUserModel
  )

  const openImportFailUserListModel = (data) => {
    d(
      handleModel({
        model: 'openImportFailUserListModel',
        state: true,
        args: { resource: 'openImportFailUserListModel', args: { data } }
      })
    )
  }

  // close model
  const closeModel = () =>
    d(handleModel({ model: 'ExportUserModel', state: false }))

  const [exportSuccess, setExportSuccess] = useState(false)
  const locationExport = useSelector(
    (state) => state.owner?.userExport?.location
  )

  useEffect(() => {
    if (exportSuccess) {
      toast.success('User Export Successfully!')
      closeModel()
      window.open(locationExport, '_blank')
    }
  }, [exportSuccess, locationExport])

  const createExportUserHandler = (data) => {
    d(
      createUserExportAsyncThunk({
        data,
        callBack: () => {
          setExportSuccess(true)
        }
      })
    )
  }

  // import
  const [formData, setFormData] = useState({})
  const [usersImportFile, setUsersImportFile] = useState()
  const isLoadingImport = useSelector(
    (s) => s?.owner?.loadings?.createUserImportAsyncThunk
  )
  const [isLoading, setLoading] = useState(false)

  // function inputForm(e, key) {
  //   let data = { ...formData };
  //   let file = URL.createObjectURL(e.target.files[0]);
  //   data[key] = e.target.files[0];
  //   setUsersImportFile(file);
  //   setFormData(data);
  // }

  function inputForm (e, key, setFieldValue) {
    const data = { ...formData }
    const file = e.target.files[0] // Get the selected file
    data[key] = file
    setUsersImportFile(URL.createObjectURL(file))
    setFormData(data)

    // Update the Formik field value to trigger validation
    setFieldValue('usersImportFile', file)
  }

  const createImportUserHandler = () => {
    setLoading(true)

    const form = new FormData()
    if (formData.usersImportFile) {
      form.append('usersImportFile', formData.usersImportFile)
    }

    d(
      createUserImportAsyncThunk({
        data: form,
        callBack: (res) => {
          toast.success('User Import Successfully!')
          closeModel()
          {
            res?.user?.datatata?.map((data, i) => {
              if (data?.errors[0]?.code) {
                openImportFailUserListModel(res?.user?.datatata)
              }
            })
          }
        }
      })
    ).finally(() => {
      setLoading(false)
    })
  }

  const [search, setSearch] = useState('')

  // role
  const typesFields = [
    { id: 1, name: 'name' },
    { id: 2, name: 'email' },
    { id: 3, name: 'nickname' },
    { id: 4, name: 'givername' },
    { id: 5, name: 'username' }
  ]
  const options =
    typesFields &&
    typesFields?.map((e) => {
      return { value: e.id, label: e.name }
    })

  //

  return (
    <Modal
      animation={false}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={state}
      onHide={closeModel}
    >
      <Modal.Body className='invite-model'>
        <h3>User Import/Export</h3>

        <Tab.Container id='export-import-tabs' defaultActiveKey='export'>
          <Nav variant='tabs' className='mb-3'>
            <Nav.Item>
              <Nav.Link eventKey='export'>Export</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='import'>Import</Nav.Link>
            </Nav.Item>
          </Nav>

          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}

          <Tab.Content>
            <Tab.Pane eventKey='export'>
              <Formik
                initialValues={{
                  connection: 'Username-Password-Authentication',
                  fields: ''
                }}
                onSubmit={createExportUserHandler}
                validationSchema={UserExportSchema}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values,
                  errors,
                  touched
                }) => (
                  <div className='row'>
                    <div className='col-lg-12'>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Connection</Form.Label>
                        <Form.Control
                          type='text'
                          disabled
                          placeholder='Enter Connection'
                          onChange={handleChange('connection')}
                          onBlur={handleBlur('connection')}
                          value={values?.connection}
                        />
                        <ErrorMassage
                          error={errors?.connection}
                          visible={touched?.connection}
                        />
                      </Form.Group>
                    </div>
                    <div className='col-lg-12'>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Fields</Form.Label>
                        <Select
                          options={options}
                          name='fields'
                          onInputChange={(e) => {
                            setSearch(e)
                          }}
                          onChange={(selectedOptions) => {
                            const selectedFieldsNames = selectedOptions.map(
                              (option, index) => {
                                return {
                                  name: option.label
                                }
                              }
                            )
                            setFieldValue('fields', selectedFieldsNames)
                          }}
                          value={options.find(
                            (option) => option.label === values.fields
                          )}
                          onBlur={handleBlur('fields')}
                          isSearchable={false}
                          isMulti
                        />
                        <ErrorMassage
                          error={errors.fields}
                          visible={touched.fields}
                        />
                      </Form.Group>
                    </div>

                    <div className='btns'>
                      <Button variant='danger' onClick={closeModel}>
                        Close
                      </Button>

                      <CustomButton
                        reducer='member'
                        action='createUserExportAsyncThunk'
                        title='Export'
                        onClick={handleSubmit}
                        loadingText='Exporting...'
                        className='btn btn-primary'
                      />
                    </div>
                  </div>
                )}
              </Formik>
            </Tab.Pane>

            {/*  */}
            {/*  */}
            {/* import */}
            {/*  */}
            {/*  */}

            <Tab.Pane eventKey='import'>
              <Formik
                initialValues={{
                  usersImportFile: null
                }}
                onSubmit={createImportUserHandler}
                validationSchema={UserImportSchema}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values,
                  errors,
                  touched
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <Form.Group className='mb-3' controlId='formBasicFile'>
                          <Form.Label
                            style={{
                              marginLeft: '10px',
                              marginRight: '10px'
                            }}
                          >
                            Download Example File :
                          </Form.Label>
                          <a
                            href='https://content.ilmiyacdn.com/assets/csv/sample_import.csv'
                            target='_blank'
                            rel='noreferrer'
                          >
                            <FileDownloadOutlinedIcon />
                          </a>
                          <br />
                          <br />
                          <Form.Label>File : </Form.Label>
                          <input
                            type='file'
                            onChange={(e) =>
                              inputForm(e, 'usersImportFile', setFieldValue)}
                            name='usersImportFile'
                            className='absolute inset-0 w-full h-full cursor-pointer'
                            style={{
                              marginLeft: '10px',
                              marginRight: '10px'
                            }}
                          />

                          <ErrorMassage
                            error={errors.usersImportFile}
                            visible={touched.usersImportFile}
                          />
                        </Form.Group>
                      </div>

                      <div className='btns'>
                        <Button variant='danger' onClick={closeModel}>
                          Close
                        </Button>
                        <Button
                          type='submit'
                          className='btn btn-primary'
                          disabled={isLoading}
                        >
                          {isLoading ? 'Loading...' : 'Import'}
                        </Button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  )
}
