import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { ApiRequests } from '../../service/ApiRequests'

function Example ({ user = {}, show = true, handleClose = () => {} }) {
  const handleGrantAccess = async (e) => {
    e.preventDefault()
    await ApiRequests.confirmAssignRolesToOwner(user.uid)
  }

  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>User Already Exist.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        User already exists with email:
        <p className='text-primary'>{user?.email}</p>
        and name: <p className='text-primary'>{user?.displayName}</p>
        if you want to assign role to this user click on grant access button.
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        <Button variant='primary' onClick={handleGrantAccess}>
          Grant Access
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Example
