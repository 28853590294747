import React from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init()
export default function Loadingdata ({ customHeight }) {
  return (
    <div className='loading-div' style={customHeight}>
      <div className='cube-loading-outer'>
        <div className='cube-wrapper'>
          <div className='cube-folding'>
            <span className='leaf1' />
            <span className='leaf2' />
            <span className='leaf3' />
            <span className='leaf4' />
          </div>
          <span className='loading' data-name='Loading'>
            LOADING
          </span>
        </div>
      </div>
    </div>
  )
}
