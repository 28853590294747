import React, { useState, useEffect } from 'react'
import { auth } from '../config/firebase'
import { useDispatch, useSelector } from 'react-redux'
import { handleModel } from '../redux/layoutSlices/modelSlice'
import { classSpace, navLink, redirect_uris, space } from '../constants'
import IlmPortalNavbar from '../components/ilm-nav/IlimiyaNavigation.jsx'
import { logout } from '../helpers/logout.js'
import { ApiRequests } from '../service/ApiRequests.js'

const HeaderLayout = ({ children }) => {
  const customToken = localStorage.getItem('custom-token')

  const d = useDispatch()
  const modelState = useSelector((s) => s?.model?.modelState?.LeftSidebarBase)
  const [orgsName, setOrgsName] = useState('')
  const [loading, setLoading] = useState(false)
  console.log('🚀 ~ HeaderLayout ~ orgsName:', orgsName)
  const [orgs, setOrgs] = useState({
    limit: 10,
    page: 1,
    results: [],
    totalPages: 1,
    totalResults: 1
  })
  const fetchOrgs = async (params) => {
    const res = await ApiRequests.getOrganizationsMembership({
      ...params,
      ...(orgsName ? { name: orgsName } : {})
    })
    setOrgs(res.data)
  }
  useEffect(() => {
    fetchOrgs({})
  }, [orgsName])
  const toggleMenu = () => {
    // d(
    //   handleModel({
    //     model: "LeftSidebarBase",
    //     state: !modelState,
    //     args: { resource: "leftSidebar" },
    //   })
    // );
  }
  return (
    <>
      <IlmPortalNavbar
        onPortalChange={(e) => {
          const url =
            redirect_uris[e] +
            `?token=${customToken}&space_id=${classSpace().class_id}`
          window.open(url, '_blank')
        }}
        sidebar={false}
        onSwitchOrg={() => {
          window.open(redirect_uris.Login + 'organizations', '_blank')
        }}
        onChangeOrgName={(e) => {
          setOrgsName(e)
        }}
        onChangeOrg={(org) => {
          const newUrl = window.location.href.replace(space, org.displayName)
          window.open(newUrl, '_blank')
        }}
        sidebarUris={navLink}
        orgs={orgs.results}
        auth={auth}
        urls={navLink}
        // logout={logout}
        logout={() => logout(setLoading)}
        toggleMenu={toggleMenu}
        menuState
        switchPopup
        loading={loading}
      >
        {children}
      </IlmPortalNavbar>
    </>
  )
}
export default HeaderLayout
